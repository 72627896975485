.input-group {
  position: relative;
  max-width: rem-calc($input-group-max-width);
  display: flex;

  & > :first-child, :first-child.input-group-button > * {
    width: 100%;
  }

  &-field {
    height: auto;
    border-right: none;
    @include breakpoint (medium) {
      line-height: $input-line-height-medium;
      font-size: $large-font-size;
    }

    &[type=text], &[type=email], &[type=password] {
      border-right: none;
      border-radius: $global-radius 0 0 $global-radius;
    }
  }
  &-button {
    button {
      padding-top: 0;
      padding-bottom: 0;
      @include setInteractiveStyle($secondary-reversed: true);
    }
  }

  &--password {
    margin-bottom: $global-margin * 0.75;
    .input-group-field {
      border-bottom-left-radius: $global-radius;
      border-top-left-radius: $global-radius;
      padding-right: rem-calc(42);
    }
    + button.clear {
      margin-left: rem-calc(6);
    }
  }
  &--postcode {
    .input-group {
      &-field {
        letter-spacing: .2rem;
      }
    }
  }
  &--code {
    margin-bottom: rem-calc($global-margin * 1.25);
    max-width: rem-calc(300);
    margin-right: auto;
    margin-left: auto;
    .input-group {
      &-field {
        letter-spacing: .7rem;
        padding-left: $global-padding;
        padding-right: 0;
        font-size: $largest-font-size;
        @include breakpoint(small-mobile) {
          letter-spacing: 1.17rem;
        }
      }
      &-button {
        .button {
          font-size: rem-calc(17);
          .icon {
            @include box(20px, 20px, false)
          }
        }
      }
    }
  }

  &--with-info-text, &--with-link {
    margin-bottom: 0;
  }
}

.form--centre-selector {
  .input-group {
    &-field, &-button {
      position: relative;
      z-index: $input-group-base-z-index;
    }
    &-field {
      padding-right: calc(3rem + 10px);
    }
  }
}

@mixin animate(
  $name,
  $attribute,
  $fromVal,
  $toVal,
  $duration,
  $timing,
  $fillMode,
  $zIndex
) {
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-name: $name;
  animation-fill-mode: $fillMode;
  @include keyframe-animation($name, $attribute, $fromVal, $toVal, $zIndex);
}

@mixin pipedListSeparator(
  $from,
  $to,
  $width,
  $color,
  $height,
  $top: false,
  $right: false
) {
  @for $i from $from through $to {
    li:nth-child(#{$i}) {
      padding-right: $width;
      position: relative;

      &:after {
        border-right: 1px solid $color;
        content: ' ';
        @if not($top) {
          @include vertical-center;
        } @else {
          top: rem-calc($top);
        }
        @if not $right {
          right: $width*0.5;
        } @else {
          right: rem-calc($right);
        }
        width: $width;
        color: $color;
        height: $height;
      }
    }
  }
}

@mixin responsiveBackgroundImage() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin addGutterWidthNegativeMargins(
  $small-breakpoint: true,
  $medium-breakpoint: true,
  $large-breakpoint: false
) {
  @if $small-breakpoint {
    margin-left: $grid-column-gutter-single-small * -1;
    margin-right: $grid-column-gutter-single-small * -1;
  }

  @if $medium-breakpoint {
    @include breakpoint(medium) {
      margin-left: $grid-column-gutter-single-medium * -1;
      margin-right: $grid-column-gutter-single-medium * -1;
    }
  } @else {
    @include breakpoint(medium) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @if $large-breakpoint {
    @include breakpoint(large) {
      margin-left: $grid-column-gutter-single-medium * -1;
      margin-right: $grid-column-gutter-single-medium * -1;
    }
  } @else {
    @include breakpoint(large) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@mixin makeFullWidth(
  $small-breakpoint: true,
  $medium-breakpoint: true,
  $large-breakpoint: false,
  $gutter: false
) {
  @if $gutter {
    @if $small-breakpoint {
      width: calc(100% - #{map-get($grid-column-gutter, small)});
      @include breakpoint(medium) {
        width: auto;
      }
    }

    @if $medium-breakpoint {
      @include breakpoint(medium) {
        width: calc(100% - #{map-get($grid-column-gutter, medium)});
      }
    }

    @if $large-breakpoint {
      @include breakpoint(large) {
        width: calc(100% - #{map-get($grid-column-gutter, medium)});
      }
    }
  } @else {
    @if $small-breakpoint {
      width: calc(100% + #{map-get($grid-column-gutter, small)});
    }

    @if $medium-breakpoint {
      @include breakpoint(medium) {
        width: calc(100% + #{map-get($grid-column-gutter, medium)});
      }
      @include breakpoint(large) {
        width: auto;
      }
    } @else {
      @include breakpoint(medium) {
        width: auto;
      }
    }

    @if $large-breakpoint {
      @include breakpoint(large) {
        width: calc(100% + #{map-get($grid-column-gutter, medium)});
      }
    }
  }
}

@mixin setInteractiveStyle(
  $link: false,
  $shadow: false,
  $icon-border: false,
  $inset: false,
  $not-inset: false,
  $primary: false,
  $primary-hollow: false,
  $secondary: false,
  $secondary-reversed: false,
  $light: false,
  $radio-checkbox: false,
  $clear: false,
  $browse-subject: false,
  $browse-topic: false,
  $browse-my-learning: false,
  $icon: false,
  $input: false,
  $close-button: false,
  $subject-complete-next-steps: false
) {
  @media (hover: hover) and (pointer: fine) {
    &:hover, &:focus-visible
     {
      &:not([disabled], .active) {
        @if ($link) {
          text-decoration: none;
        }
        @if ($link and $light) {
          color: $secondary-dark;
        }
        @if ($link or $primary) {
          color: $primary-dark;
          @if ($icon) {
            .icon {
              fill: $primary-dark;
            }
          }
        }
        @if($icon-border) {
          .icon {
            border-color: $primary-dark!important;
          }
        }
        @if ($primary or $close-button) {
          border-color: $primary-dark;
        }
        @if ($primary or $secondary or $close-button) {
          background: none $white;
        }
        @if ($light) {
          background: none transparent;
          border-color: white;
          box-shadow: none;
          color: white;
          .icon {
            fill: white;
          }
        }
        @if ($light and $close-button) {
          border-color: transparent;
          .icon {
            fill: $secondary-dark;
          }
        }
        @if ($primary-hollow or $browse-subject or $browse-topic or $browse-my-learning or $subject-complete-next-steps) {
          color: white;
          @include shadow(none);
          background: none $primary-dark;
          @if ($primary-hollow) {
            border-color: $primary-dark;
          }
          @if ($browse-subject or $browse-topic or $primary-hollow or $browse-my-learning or $subject-complete-next-steps) {
            .icon {
              fill: white;
            }
          }
          @if ($browse-topic or $browse-my-learning or $subject-complete-next-steps) {
            background: none $secondary-dark;
          }
          @if ($browse-my-learning or $browse-topic or $subject-complete-next-steps) {
            border-color: $secondary-dark;
          }
        }
        @if ($secondary) {
          border-color: $secondary-dark;
          color: $secondary-dark;
          .icon {
            fill: $secondary-dark;
          }
        }
        @if ($secondary-reversed) {
          background: none $secondary-dark;
          border-color: $secondary-dark;
          color: white;
          .icon {
            fill: white;
          }
        }
        @if ($clear) {
          text-decoration: none;
          color: $primary-dark;
          .icon {
            fill: $primary-dark;
          }
        }
      }
    }
  }
  &:focus-visible, &:active {
    &:not([disabled], .active) {
      outline: unset;
      @include shadow(0 0 0 2px $primary-dark);
      background-color: white;
      color: $primary-dark;
      .icon {
        fill: $primary-dark;
        @if($icon-border) {
          border-color: $primary-dark!important;
        }
      }
      @if ($input) {
        color: inherit;
      }
      @if ($link) {
        @include shadow(none);
        outline: revert;
        outline-color: $primary-dark;
        outline-width: 2px;
      }
      @if ($shadow) {
        @include shadow(0 0 0 2px $primary-dark);
      }
      @if ($clear or $inset) {
        @include shadow(inset 0 0 0 2px $primary-dark);
        border-color: transparent;
      }
      @if ($not-inset) {
        @include shadow(0 0 0 2px $primary-dark);
      }
      @if ($radio-checkbox) {
        + label {
          @include shadow(inset 0 0 0 2px $primary-dark);
        }
      }
      @if ($secondary-reversed) {
        @include shadow(inset 0 0 0 2px $primary-dark);
      }
      @if ($light) {
        background: none transparent;
        border-color: white;
        color: white;
        box-shadow: none;
      }
      @if ($light and $close-button) {
        background: none transparent;
        border-color: white;
        .icon {
          fill: white;
        }
      }
    }
  }
}

@mixin positionElementVerticalCenter() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin positionElementHorizontalCenter() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

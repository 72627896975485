button,
.button {
  &.square {
    min-height: rem-calc(65);
    min-width: rem-calc(65);
    border-color: transparent;
    @include shadow(none);
    font-weight: $global-weight-normal;
    font-size: initial;
    color: $secondary-dark;
    line-height: $button-text-lineheight;
    padding: rem-calc($grid-column-gutter-single-small*0.5) rem-calc($grid-column-gutter-single-small);
    @include breakpoint(large-mobile) {
      &.hollow {
        @include shadow(0 2px 0 $light-gray);
      }
      border-color: $primary-light;
      padding-left: rem-calc($grid-column-gutter-single-small) * 0.5;
      padding-right: rem-calc($grid-column-gutter-single-small) * 0.5;
    }
    @include breakpoint(large) {
      min-height: rem-calc(77);
      min-width: rem-calc(77);
      font-size: $large-font-size;
      padding-left: rem-calc($grid-column-gutter-single-small);
      padding-right: rem-calc($grid-column-gutter-single-small);
    }
    &:last-child {
      margin-left: $grid-column-gutter-single-small;
    }
    @include setInteractiveStyle($primary: true, $icon: true, $inset: true);
  }
}

button,
.button {
  &.hollow {
    @include shadow(0 2px 0 $light-gray);
    color: $primary-dark;
    font-size: $global-font-size;
    border-color: $primary;
    background: none white;
    line-height: $button-text-lineheight;
    padding: rem-calc($grid-column-gutter-single-small*0.5)
      rem-calc($grid-column-gutter-single-small);
    @include setInteractiveStyle($primary-hollow: true);
    &.subject-complete {
      border-radius: 0;
      border-left: none;
      border-right: none;
      border-color: $secondary-lightest;
      justify-content: space-between;
      color: $secondary-dark;
      font-weight: normal;
      min-height: rem-calc(50);
      margin-bottom: -1px;
      @include makeFullWidth($medium-breakpoint: false);
      @include addGutterWidthNegativeMargins($medium-breakpoint: false);
      @include shadow(none);
      @include setInteractiveStyle($subject-complete-next-steps: true, $inset: true);
      @include breakpoint(medium) {
        @include shadow(0 2px 0 $light-gray);
        width: 100%;
        margin: 0;
        border-radius: $global-radius;
        max-width: rem-calc(370);
        margin-bottom: $global-margin;
        border: 1px solid $secondary-lighter;
        padding: rem-calc($grid-column-gutter-single-medium*0.5)
          rem-calc($grid-column-gutter-single-medium)
          rem-calc(6)
          rem-calc($grid-column-gutter-single-medium);
        font-size: $large-font-size;
        text-decoration: none;
      }
    }
  }
}

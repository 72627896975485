select {
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='36' height='36' viewBox='0 0 24 24'><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' style='fill: %232a5065'></path></svg>");
  background-size: rem-calc(36);
  background-repeat: no-repeat;
  background-position: right center;
  min-height: rem-calc(40);
  transition: none;
  padding-right: $global-padding * 2.25;
  padding-left: $global-padding;
  height: auto;
  @include shadow(inset 2px -2px 4px rgba(0, 0, 0, 0.15));
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    @include shadow(inset 2px -2px 4px rgba(0, 0, 0, 0.15));
    outline: unset;
  }
}

.centres-list-for-large-mobile-up {
  display: none;

  @include breakpoint(large-mobile) {
    position: absolute;
    padding-top: 2px;
    top: rem-calc(42);
    align-items: flex-start;
    background: none white;
    overflow: hidden;
    z-index: 2;
    max-height: 0;
    width: 100%;
    border: 1px solid $light-gray;
    border-top: none;
    @include shadow(0 0 10px rgba(0, 0, 0, 0.10));
    max-width: rem-calc($input-group-max-width);
    border-bottom-right-radius: $global-radius;
    border-bottom-left-radius: $global-radius;

    &__shown {
      display: flex;
      @include animate(slide-down-centres-list, max-height, 0, rem-calc(175), 0.35s, cubic-bezier(.84, 0, .17, 1), forwards, 201);

      .radio-button-group {
        overflow-y: auto;
        max-height: rem-calc(175);
        width: 100%;
        padding-bottom: 0;
        position: relative;

        .radio-button {
          &:first-child {
            border-top: none;
          }

          margin: 0;

          input[type="radio"] {
            +label {
              padding: 0 $grid-column-gutter-single-small;
            }
          }

          &__dot {
            left: $grid-column-gutter-single-medium;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  @include breakpoint(medium) {
    top: rem-calc(50);
  }
}

.centres-list-for-small-mobile {
  display: none;
  color: $body-font-color;

  &__shown {
    display: block;
    margin-top: $global-margin*0.5;
    padding-right: rem-calc(36);
  }

  @include breakpoint(large-mobile) {
    display: none;
  }
}

.button {
  &--light {
    background: none white;
    color: $secondary-dark;
    @include shadow(none);
    padding: 0 rem-calc(map-get($grid-padding-gutters, small));
    border-color: white;
    &.hollow {
      border: 1px solid white;
      color: white;
      background-color: $help-popup-background;
      @include shadow(none);
    }
  }
}
.help-popup {
  .button {
    min-height: rem-calc(36);
    &--light {
      padding: rem-calc($global-padding*0.5)
        rem-calc($grid-column-gutter-single-medium) rem-calc(6)
        rem-calc($grid-column-gutter-single-medium);
        &:first-of-type {
          margin-right: $global-margin;
        }
        @include setInteractiveStyle($light: true);
        font-size: $small-font-size;
        @include breakpoint(medium) {
          font-size: $global-font-size;
        }
    }
    &.close-button {
      .icon {
        @include box (36px, 36px, false);
      }
      @include setInteractiveStyle($light: true, $close-button: true);
    }
  }
}

.accordion {
  &__content {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    p {
      &:first-of-type {
        margin-top: $global-margin*0.5;
      }
    }
  }
}
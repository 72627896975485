*:focus-visible {
  outline: none
}

.grid-container {
  &--skip-to-content {
    position: relative;
  }
}

.site-container {
  padding-bottom: $global-padding * 4;
  p {
    max-width: rem-calc($site-prargraph-max-width);
  }
  &--case-study {
    p {
      &.lead {
        @include breakpoint(medium) {
          font-size: $global-font-size;
        }
      }
    }
  }
  &--topic-landing {
    padding-bottom: 0;
    .cell {
      &:first-of-type {
        padding-bottom: $global-padding * 4;
      }
    }
    p {
      margin-bottom: $global-margin * 1.25;
      &.topic-completed {
        padding-left: rem-calc($grid-column-gutter-single-small * 0.5);
        font-size: $small-font-size;
        @include breakpoint(medium) {
          font-size: $global-font-size;
        }
      }
    }
    .button {
      margin-bottom: $global-margin * 0.75;
    }

    .topic-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0;
      position: absolute;
      right: 0;
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
    .callout--info-box {
      bottom: 0;
      left: 0;
    }
  }

  &--error-page {
    p {
      @include breakpoint(medium) {
        font-size: 1.125rem;
      }
    }
  }

  &__footer {
    h2 {
      @include breakpoint(medium) {
        margin-top: $global-margin*0.5;
      }
    }
    &--buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: $global-margin*0.5;
      @include breakpoint(medium) {
        flex-direction: row;
        margin-top: $global-margin * 0.75;
      }
      .button {
        &.primary {
          min-width: rem-calc(270);
        }
      }
      hr {
        &.separator {
          margin-top: $global-margin * 0.75;
          margin-bottom: rem-calc($grid-column-gutter-single-small);
          @include breakpoint(medium) {
            width: 1px;
            height: rem-calc(48);
            border: none;
            border-left: 1px solid $light-gray;
            margin: 0 rem-calc(25);
          }
        }
      }
    }
  }
}

hr {
  @include makeFullWidth();
  &.section {
    border-top: 1px solid $light-gray;
    @include breakpoint(medium) {
      margin-left: 0;
      margin-right: 0;
    }
    &--divider {
      border-top: 1px dashed $light-gray;
      margin-top: $global-margin * 1.5;
      margin-bottom: $global-margin * 0.75;
      @include breakpoint(medium) {
        margin: 0 0 rem-calc(22) 0;
      }
      &--form {
        margin-top: 0;
        @include breakpoint(medium) {
          margin-top: $global-margin * 0.5;
          width: calc(100% + #{$grid-column-gutter-single-medium * 2});
          margin-left: -$grid-column-gutter-single-medium;

        }
      }
    }
  }
  &.separator {
    margin-bottom: $global-margin;
    border: none;
    border-top: 1px solid $light-gray;
    @include breakpoint(medium) {
      margin-top: rem-calc(12);
    }
  }
  @include breakpoint(medium) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.browse {
  &--my-learning__header-wrapper, &--topics__header-wrapper {
    margin-top: 1.15rem;
    margin-bottom: rem-calc($global-margin * 0.5);
    @include breakpoint(medium) {
      margin-top: 1.5rem;
    } 
  }
  &--my-learning {
    &__metadata {
      min-height: rem-calc(28);
      @include breakpoint(medium) {
        min-height: rem-calc(38);
      }
      p {
        font-size: $smallest-font-size;
        padding-left: $global-padding * 1.25;
        max-width: rem-calc(730);
        @include breakpoint(medium) {
          font-size: $small-font-size;
          padding-left: $global-padding * 1.5;
        }
      }
    }
  }
  p {
    max-width: rem-calc($site-prargraph-max-width);
    &.topic {
      &-completed, &-not-completed {
        margin-right: $global-margin * 0.25;
        margin-top: $global-margin * 0.5;
      }
      &-not-completed {
        color: $darkest-gray;
      }
    }
  }
}

.global-error-popup {
  .help-popup {
    z-index: 99999;
    top: 35%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.button {
  &--skip-to-content {
    position: absolute;
    z-index: 1008;
    top: rem-calc(-45);
    display: block;
    min-width: rem-calc(175);
    transition: top 0.3s;
    border: 1px solid $secondary-light;
    padding: rem-calc(7) rem-calc($grid-column-gutter-single-small) rem-calc(6)
      rem-calc($grid-column-gutter-single-medium);
    @include shadow(0 2px 2px rgba(0, 0, 0, 0.1));
    background: none $pale-blue;
    left: rem-calc(200);
    font-weight: $global-weight-normal;
    min-height: auto;
    border-radius: 0;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    border-top: none;
    &:focus {
      top: 0
    }
  }
}
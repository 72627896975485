.unit-player {
  &-content {
    overflow-y: auto;
    position: absolute;
    top: rem-calc(map-get($player-header-height, small));
    height: calc(
      100% - #{map-get($player-footer-height, small) +
        map-get($player-header-height, small)}
    );
    width: 100%;
    padding-top: $global-padding;

    @include breakpoint(medium) {
      top: rem-calc(map-get($player-header-height, medium));
      height: calc(
        100% - #{map-get($player-footer-height, medium) +
          map-get($player-header-height, medium)}
      );
      padding-top: rem-calc($global-padding * 1.25);
    }

    &--with-progress {
      top: rem-calc(
        map-get($player-header-height-with-progress, small) +
          map-get($progress-bar-height, small)
      );

      @include breakpoint(medium) {
        top: rem-calc(
          map-get($player-header-height-with-progress, medium) +
            map-get($progress-bar-height, medium)
        );
      }
    }

    &--learning {
      padding-top: 0; // This is dealt with by the iframe container class so we can add to the body in the iframe to stop the content jumping around when it goes to full screen
      overflow: unset;
      position: static;
    }

    &--password-step, &--verification-step {
      $spacing: map-get($player-header-height-with-progress, small) +
          map-get($progress-bar-height, small);
      @include breakpoint(large-mobile down) {
        // For Android so it scrolls the page up correctly for the help
        @supports not (-webkit-touch-callout: none) {
          top: rem-calc($spacing * -1);
          height: calc(100% + $spacing);
        }
        // and the opposite for iOS so it doesn't scroll too much.
        @supports (-webkit-touch-callout: none) {
          top: rem-calc(
            $spacing
          ) * 2;
        }
      }
    }
    &--add-space-for-android-phones-above-keyboard {
      $spacing: (map-get($player-header-height-with-progress, small) +
      map-get($progress-bar-height, small));
      @include breakpoint(large-mobile down) {
        @supports not (-webkit-touch-callout: none) {
          top: rem-calc($spacing * 0.40);
          height: calc(100% + $spacing)!important;
        }
      }
    }
    &__intro-image {
      @include responsiveBackgroundImage();
      height: rem-calc(180);
      @include breakpoint(large-mobile) {
        height: rem-calc(240);
      }

      width: 100%;
      max-width: $global-width;
      margin: 0 auto;
      opacity: 0.9;
    }

    .sign-in-details {
      position: fixed;
      bottom: rem-calc(70);
      background: none white;
      z-index: 101;
      min-height: rem-calc(48);
      @include makeFullWidth($gutter: true);

      @include breakpoint(medium) {
        bottom: rem-calc(85);
      }

      overflow: hidden;

      &:not(.collapsed) {
        .button {
          border-bottom: none;
          &--sign-in-details--staff {
            border-bottom: 1px solid $secondary-dark;
          }
          border-radius: $global-radius $global-radius 0 0;
        }

        .sign-in-details__panel {
          border: 1px solid $secondary-dark;
          border-top: none;
          margin-top: 0;
          margin-bottom: rem-calc(2);
          @include shadow(0 2px 0 $light-gray);
          @include animate(
            slide-up,
            height,
            0,
            rem-calc(272),
            0.3s,
            cubic-bezier(0.5, 0, 0.5, 1),
            forwards,
            7
          );
        }
      }

      &.animate-collapse {
        margin-bottom: rem-calc(2);
        margin-top: 0;
        @include animate(
          slide-down,
          height,
          rem-calc(272),
          0,
          0.3s,
          cubic-bezier(0.5, 0, 0.5, 1),
          forwards,
          5
        );
      }

      &__panel {
        background-color: white;
        border-radius: 0 0 $global-radius $global-radius;
        display: block;
        border: 1px solid $secondary-dark;
        margin-top: 0;

        &__details {
          padding: $global-padding * 0.8 $global-padding;
          height: 100%;
          position: relative;
          overflow-y: auto;

          dl {
            margin-bottom: $global-margin;
            dt {
              margin-bottom: 0;
            }

            dd {
              margin-bottom: $global-padding * 0.8;

              &:last-of-type {
                margin-bottom: $global-padding * 0.5;
              }
            }
          }
        }

        &__practise {
          border-top: 1px solid $light-gray;
          padding: $grid-column-gutter-single-small * 0.5 0;

          .button {
            .icon {
              margin-top: rem-calc(-3);
              margin-right: rem-calc(4);
            }
          }
        }
      }
      &.collapsed {
        .sign-in-details__panel {
          height: 0;
        }
      }
      &.collapsed, &.animate-collapse {
        .sign-in-details__panel {
          margin-top: rem-calc(3);
          margin-bottom: rem-calc(-3);
        }
      }
    }
    &__subject-complete {
      background-color: $pale-yellow;
      @include makeFullWidth($medium-breakpoint: false);
      @include addGutterWidthNegativeMargins($large-breakpoint: true);
      padding: $global-padding rem-calc($grid-column-gutter-single-small);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem-calc(22);
      @include breakpoint(medium) {
        flex-direction: column;
        align-items: center;
        padding: $global-padding * 1.5 rem-calc($grid-column-gutter-single-medium);
        margin-bottom: rem-calc(30);
      }
      &__next-steps {
        margin-bottom: rem-calc(80);
        @include breakpoint(medium) {
          align-items: center;
        }
      }
    }
  }
}

hr {
  margin-bottom: $global-padding * 0.75;
  @include addGutterWidthNegativeMargins();
}

.faded-overlay {
  background: none white;
  position: absolute;
  top: rem-calc(map-get($player-header-height-with-progress, small));
  @include addGutterWidthNegativeMargins();
  height: calc(
    100% - #{map-get($player-footer-height, medium)}
  );
  @include makeFullWidth($gutter: true, $large-breakpoint: true);
  max-width: $global-width;
  opacity: 0;

  &--fade-up-80 {
    @include animate(
      fade-up-to-80,
      opacity,
      0,
      0.8,
      0.2s,
      cubic-bezier(0.84, 0, 0.17, 1),
      forwards,
      6
    );
  }

  &--fade-down {
    @include animate(
      fade-down-to-0-from-100,
      opacity,
      1,
      0,
      0.1s,
      ease-out,
      forwards,
      -1
    );
  }
}

// Generic fade to 100% classes
.fade-up {
  @include animate(
    fade-up-to-100,
    opacity,
    0,
    1,
    0.35s,
    cubic-bezier(0.84, 0, 0.17, 1),
    forwards,
    7
  );
}

.fade-down {
  @include animate(
    fade-down-to-0-from-100,
    opacity,
    1,
    0,
    0.1s,
    ease-out,
    forwards,
    -1
  );
}

header {
  z-index: 100;
}

main {
  z-index: 5;
  max-width: rem-calc($global-width);
  margin: 0 auto;
  .iframe-container {
    margin-top: rem-calc(
      map-get($player-header-height-with-progress, small) +
        map-get($progress-bar-height, small)
    );
    @include breakpoint(medium) {
      margin-top: rem-calc(
        map-get($player-header-height-with-progress, medium) +
          map-get($progress-bar-height, medium)
      );
    }
  }
  iframe {
    width: 100%;
    height: calc(
      100% - #{rem-calc(
          map-get($player-header-height-with-progress, small) +
            map-get($progress-bar-height, small) +
            map-get($player-footer-height, small)
        )}
    );
    position: absolute;
    transform: translateY(0);
    @include breakpoint(medium) {
      height: calc(
        100% - #{rem-calc(
            map-get($player-header-height-with-progress, medium) +
              map-get($progress-bar-height, medium) +
              map-get($player-footer-height, medium)
          )}
      );
    }

    &.full-screen {
      height: 100vh;
      z-index: 10001;
      top: 0;
      @include breakpoint(medium) {
        height: 100%;
      }
    }
    &.full-height {
      @include breakpoint(medium down) {
        height: 100%;
      }
    }
  }
  .text-to-speech-container {
    position: absolute;
    @include box(36px, 36px, false);
    right: rem-calc($global-margin * 0.75);
    top: rem-calc($global-margin  * 0.75);
    @include breakpoint(medium) {
      @include box(44px, 44px, false);
      right: rem-calc(18px);
      top: rem-calc($global-margin);
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



.button {
  &--icon-on-right {
    padding-left: $global-padding * 0.75;
    padding-right: $global-padding * 0.5;
    .icon {
      flex: 1 0 auto;
      @include breakpoint(medium) {
        flex-grow: 0;
      }
      &:not(&--material) {
        margin: 0 0 0 rem-calc(4);
      }
      &--loader {
        margin-top: rem-calc(-1.5)!important;
      }
      &--arrow-right {
        margin-top: rem-calc(1);
      }
      &--material {
        margin-top: rem-calc(2);
      }
      &--print {
        margin-left: rem-calc(6);
        @include breakpoint(medium) {
          margin-left: rem-calc($global-margin * 0.5);
        }
      }
    }
    &--done {
      &.wales {
        width: rem-calc(100);
        @include breakpoint(medium) {
          width: rem-calc(120);
        }
      }
    }
  }

  &--icon-on-left {
    .icon {
      margin-right: rem-calc(4);
      margin-top: rem-calc(-3);
      &--material-inline {
        margin-bottom: rem-calc(5);
      }
      &.centered {
        margin-left: rem-calc(-12);
      }
      @include breakpoint(medium) {
        flex-grow: 0;
      }
      &--send-code, &--loader, &--circle-checkmark {
        margin-right: rem-calc($global-margin * 0.5);
      }
      &--loader {
        margin-bottom: rem-calc(-2);
      }
      &--send-code {
        margin-top: rem-calc(-2);
      }
      &--circle-checkmark, &--hourglass-bottom {
        fill: white;
      }
      &--hourglass-bottom {
        margin-top: rem-calc(2);
      }
      &--circle-checkmark {
        margin-top: rem-calc(3);
      }
      &.centered-button {
        margin-left: -$global-margin * 0.25;
      }
      &--arrow-up {
        margin-top: 0;
      }
      &--restart {
        margin-top: rem-calc(-2);
      }
    }
  }

  &--icon-on-right {
    .icon--send-code {
      flex: 0 1 auto;
      margin-left: $global-margin * 0.5;
      margin-top: rem-calc(-1);
    }
  }

  &--restart {
    max-width: rem-calc(300);
    .icon {
      margin-top: 0;
      flex: 0 0 auto;
    }
  }

  &--icon-on-top {
    $visible-z-index: $input-group-base-z-index + 1;

    justify-content: space-between;
    padding-bottom: rem-calc(5);
    &.border-transparent{
      @include setInteractiveStyle($link: true, $icon: true, $clear: true);
    }

    .icon {
      margin-top: rem-calc(4);
      @include breakpoint(large) {
        margin-top: rem-calc(6);
      }

      &--material {
        margin-top: 0;
        + .icon-text {
          margin-top: rem-calc(-6);
        }
      }
    }

    &-inline {
      right: rem-calc(92);
      @include box(rem-calc(42), rem-calc(36));
      @include breakpoint(medium) {
        right: rem-calc(98);
        @include box(rem-calc(48), rem-calc(42));
      }
      &.wales {
        right: rem-calc(100);
        @include box(rem-calc(70), rem-calc(42));
        @include breakpoint(medium) {
          right: rem-calc(120);
        }
      }
      .button__inner {
        right: 0;
        justify-content: center;
        width: 100%;
        margin-top: 0;
        visibility: visible;
        z-index: $visible-z-index;

        .icon {
          margin-top: 0;
          @include box(rem-calc(24), rem-calc(24));
          flex: 1 0 rem-calc(24);
          @include breakpoint(medium) {
            @include box(rem-calc(28), rem-calc(28));
            flex-basis: rem-calc(28);
          }
          &-text {
            font-size: rem-calc(15);
            @include breakpoint(medium) {
              font-size: rem-calc(16);
            }
            white-space: nowrap;
            font-weight: $global-weight-bold;
            line-height: 1;
            margin-top: rem-calc(-2);
          }
        }
      }
    }

    &[disabled], &.disabled {
      z-index: $visible-z-index;
    }
  }

  &--mobile-menu {
    z-index: 2;
    .icon--exit {
      @include box(44px, 44px, false);
      margin-top: -12px;
    }
  }

  &--sign-in-details {
    letter-spacing: 0;
    padding: rem-calc(4) 0;
    z-index: 999999;
    justify-content: space-between;
    width: 100%;

    span span {
      vertical-align: middle;
    }

    @include breakpoint(medium) {
      font-size: $large-font-size;
    }
    .icon {
      &--user {
        @include box(rem-calc(26), rem-calc(26), false);
        margin: rem-calc(-2) rem-calc(5) 0 rem-calc(8);
      }

      &--chevron-up,
      &--chevron-down {
        @include box(rem-calc(36), rem-calc(36), false);
        margin-left: rem-calc(-3);
        margin-top: rem-calc(-1);
      }
    }
    .panel-open-close {
      font-size: $small-font-size;
      @include breakpoint(medium) {
        font-size: 96.75%;
      }
    }
  }

  &--text-to-speech {
    @include box(36px, 36px, false);
    &:active {
      @include box(34px, 34px, false);
    }
    @include breakpoint(medium) {
      right: rem-calc($grid-column-gutter-single-small);
      top: rem-calc($grid-column-gutter-single-small / 2);
      @include box(44px, 44px, false);
      &:active {
        @include box(42px, 42px, false);
      }
    }
    border-radius: 50%;
    box-shadow: 0 0 16px rgba(0,0,0,.25);
    border: 2px solid $primary;
    background-color: white;
    .icon {
      &--speaker {
        @include box(24px, 22.15px, false);
        @include breakpoint(medium) {
          @include box(30px, 27.69px, false);
        }
        fill: $primary;
      }
    }
    &:hover {
      background-color: $primary;
      .icon {
        &--speaker {
          fill: white;
        }
      }
    }
    &.playing {
      &:after {
        content: '';
        width: rem-calc(18);
        height: rem-calc(18);
        position: absolute;
        top: rem-calc(9); 
        left: rem-calc(9);
        background-color: $primary;
        border-radius: 2px;
      }
      @include breakpoint(medium) {
        &:after {
          width: rem-calc(22);
          height: rem-calc(22);
          top: rem-calc(11); 
          left: rem-calc(11);
        }
      }
      &:hover {
        background-color: $primary;
        &:after {
          background-color: white;
        }
      }
    }
  }
  &.close-button {
    position: absolute;
    width: auto;
    box-shadow: none;
    right: rem-calc(4);
    top: rem-calc(4);
    min-height: auto;
    border: 1px solid transparent;
    @include setInteractiveStyle($close-button: true);
    .icon {
      @include box(rem-calc(36), rem-calc(36), false);
    }
    @include breakpoint(medium) {
      .icon {
        @include box(rem-calc(42), rem-calc(42), false);
      }
    }
  }
  &.hollow {
    .icon {
      &--loader {
        rect {
          fill: $primary-dark;
        }
      }
    }
  }
  &.clear {
    .icon {
      &--loader {
        rect {
          fill: $secondary-dark;
        }
      }
    }
  }
}

.form--full-page {
  .form-item__wrapper {
    .button--icon-on-top-inline {
      left: auto;
      top:0;
      right: 0.5rem;
    }
  }
}

.header__top ul li:first-child {
  a {
    &.button {
      &.clear {
        @include setInteractiveStyle($link: true, $icon: true);
      }
    }
  }
}

.form--input-group__inner--verification-code {
  .button {
    &--icon-on-right {
     padding-left: rem-calc(6);
    }
  }
}



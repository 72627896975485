.image-with-blockquote {
  @include makeFullWidth($medium-breakpoint: false);
  @include addGutterWidthNegativeMargins($medium-breakpoint: false);
  margin-bottom: $global-margin;

  @include breakpoint(medium) {
    min-height: rem-calc(290);
  }

  @include breakpoint(tablet-portrait) {
    margin-bottom: 0;
  }

  &--secondary {
    @include breakpoint(tablet-portrait) {
      margin-top: $global-margin * 2.5;
      min-height: auto;
    }
  }

  &__image {
    min-height: rem-calc(160);
    @include responsiveBackgroundImage();
    background-position-y: 16%;

    @include breakpoint(medium) {
      background-position-y: center;
      margin-bottom: 0;
      min-height: rem-calc(228);
      border-top-left-radius: $large-image-radius;
      border-top-right-radius: $large-image-radius;
    }
    &--secondary {
      min-height: rem-calc(224);
      @include breakpoint(tablet-portrait) {
        min-height: rem-calc(292);
      }
    }
  }

  &__blockquote {
    flex-basis: 100%;
    border-left: none;
    background: none $secondary-dark;
    padding: rem-calc($grid-column-gutter-single-small);
    margin-bottom: 0;

    @include breakpoint(medium) {
      border-bottom-left-radius: $large-image-radius;
      border-bottom-right-radius: $large-image-radius;
    }

    @include breakpoint(large) {
      padding: rem-calc($global-padding);
    }

    p {
      color: white;
      font-size: $global-font-size;
      line-height: 1.375;
      align-self: center;
    }

    .icon {
      fill: white;
      min-width: rem-calc(30);
      width: rem-calc(30);
      height: rem-calc(30);
      margin: rem-calc(-$global-margin*0.25) rem-calc($grid-column-gutter-single-small+2) 0 0;
      align-self: flex-start;

      @include breakpoint(large) {
        align-self: center;
        margin-top: rem-calc(-$global-margin*0.5);
        min-width: rem-calc(46);
        width: rem-calc(46);
        height: rem-calc(46);
        margin-right: rem-calc($grid-column-gutter-single-small*2);
      }
    }
    &--secondary {
      background: none $pale-blue;
      border-bottom: 1px solid $secondary-lightest;
      @include breakpoint(tablet-portrait) {
        border: 1px solid $secondary-lightest;
        border-top: none;
      }
      p {
        font-size: rem-calc(15);
        letter-spacing: 0.01em;
        font-weight: $global-weight-bold;
        color: $secondary-dark;
        @include breakpoint(large) {
          font-size: $global-font-size;
        }
      }
      .icon {
        fill: $secondary-dark;
        @include breakpoint(large) {
          align-self: flex-start;
          margin-right: rem-calc($grid-column-gutter-single-small*1.5);
        }
      }
    }
  }
}
button,
.button {
  &.small {
    &-link {
      font-size: $small-font-size;
      @include breakpoint(large) {
        font-size: $global-font-size;
      }
    }
  }
  &.large {
    padding: rem-calc($grid-column-gutter-single-small*0.5)
      rem-calc($grid-column-gutter-single-small)
      rem-calc($grid-column-gutter-single-small*0.5)
      rem-calc($grid-column-gutter-single-small);
    line-height: $button-text-lineheight;
    @include breakpoint(medium) {
      padding: rem-calc($global-padding*0.5)
        rem-calc($grid-column-gutter-single-medium) rem-calc($global-padding*0.5)
        rem-calc($grid-column-gutter-single-medium);
      min-height: rem-calc(48);
      font-size: $largest-font-size;
    }
  }
}

.sign-in--password {
  .button {
    &.small {
      font-size: $global-font-size;
      min-height: rem-calc(44);
      @include breakpoint(medium) {
        min-height: rem-calc(44);
        font-size: $global-font-size;
      }
    }
  }
}
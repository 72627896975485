.button {
  &--accordion {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    line-height: 1.1875;
    text-align: left;
    background-color: $pale-gray;
    border-color: $medium-gray;
    color: $secondary-dark;
    background-image: none;
    @include shadow(inset 0 2px 0 white);
    padding: rem-calc($global-padding*0.5) 0 rem-calc($global-padding*0.5)
    rem-calc($grid-column-gutter-single-small);
    justify-content: space-between;
    @include makeFullWidth;
    margin-left: -$grid-column-gutter-single-small;
    transition: border-bottom-color 0.2s ease-out, background-image 0.2s ease-out;
    .icon {
      transition: transform 0.2s ease-out;
      &--material {
        margin-right: 0;
        @include box(rem-calc(36), rem-calc(36), false);
        min-width: rem-calc(36);
      }
    }
    h2 {
      font-size: $largest-font-size;
      color: $secondary-dark;
      font-weight: $global-weight-bold;
      margin-bottom: 0;
      line-height: $button-text-lineheight;
    }
    &.active {
      background-color: white;
      border-bottom-color: white;
      background-image: linear-gradient(180deg, $pale-gray 15%, white 100%);
      > .icon {
        transform: rotate(180deg);
      }
    }
    &:focus, &:active {
      outline: none!important;
    }
  }
}
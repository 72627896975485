button,
.button {
  &.secondary {
    @include shadow(none);
    border-color: $secondary-dark;
    color: white;
    background: none $secondary-dark;
    @include setInteractiveStyle($secondary: true);
    &-reversed {
      color: $secondary-dark;
      background: none white;
      @include setInteractiveStyle($secondary-reversed: true);
      @include shadow(0 2px 0 $light-gray);
    }
  }
}

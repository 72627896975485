.site-container {
  &--help {
    section {
      max-width: rem-calc(852);
      &:first-of-type {
        margin-top: rem-calc($grid-column-gutter-single-small);
        @include breakpoint(medium) {
          margin-top: 0;
        }
      }
      margin-bottom: rem-calc($grid-column-gutter-single-small);
      &:last-of-type {
        margin-bottom:0;
        @include breakpoint(medium) {
          margin-bottom: rem-calc(30);
        }
      }
      @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
      }
      h2 {
        margin-bottom: $global-margin * 1.25;
      }
      img {
        @include shadow(0 0 8px rgba(0,0,0,0.15));
        border: 1px solid $lighter-gray;
        max-width: calc(100% - #{map-get($grid-column-gutter, small)*0.5});
        margin: $global-margin auto $global-margin*0.75 auto;
        display: block;
        @include breakpoint(medium) {
          margin: $global-margin*1.75 0 $global-margin*2 0;
          @include shadow(0 0 30px rgba(0,0,0,0.25));
          max-width: 100%;
        }
      }
    }
    hr {
      &.separator {
        &.top {
          margin-top: -1px;
          @include breakpoint(medium) {
            margin-top: $global-margin*0.75;
          }
        }
      }
    }
  }
}
.help-popup {
  &-button-strip {
    width: 100%;
    background: none $pale-gray;
    min-height: rem-calc(44);
  }
  width: 100%;
  background-color: $help-popup-background;
  color: white;
  border-radius: $global-radius;
  padding: rem-calc($global-padding);
  position: absolute;
  @include shadow(-3px 2px 3px rgba(0, 0, 0, 0.25));

  @include breakpoint(medium) {
    max-width: rem-calc(400);
  }

  h3 {
    color: white;
    display: flex;
    align-items: flex-start;
    line-height: $help-popup-heading-lineheight;
    font-size: $global-font-size * 1.0625;
    font-weight: $global-weight-bold;
    margin-bottom: rem-calc($global-margin * 0.75);
    width: 87%;
    span {
      padding-top: rem-calc(2);
    }
    .icon {
      &--help-circle {
        border-color: white;
        margin-right: rem-calc($global-margin*0.5);
      }
    }
  }

  p {
    line-height: $help-popup-message-lineheight;
    margin-bottom: rem-calc($global-margin * 0.75);
    &.indented {
      text-align: center;
      margin-left: -($global-padding * 1.5);
    }
  }

  .pointer {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    left: $global-padding;
    z-index: 1007;
    position: absolute;
  }

  &--sign-in,
  &--password,
  &--bottom,
  &--registration-email-mobile {
    .pointer {
      border-bottom: 15px solid $help-popup-background;
      top: rem-calc(-14);
    }
  }

  &--registration-email-mobile,
  &--bottom,
  &--top {
    max-width: calc(100% - #{$grid-column-gutter-single-small});
    z-index: 1007;
    @include breakpoint(large-mobile) {
      max-width: rem-calc(390);
    }
  }
  &--registration-verification-code {
    max-width: rem-calc($input-group-code-max-width);
  }

  &--full-page-form-with-pointer {
    left: rem-calc($global-padding);
    max-width: calc(100% - #{$global-padding * 2});
    @include breakpoint(large-mobile) {
      max-width: rem-calc(390);
    }
    @include breakpoint(medium) {
      left: rem-calc($global-padding * 2);
      max-width: rem-calc($input-full-page-form-max-width);
    }
    .pointer {
      bottom: rem-calc(-14px);
      border-top: 15px solid $help-popup-background;
    }
  }

  &--password,
  &--sign-in {
    max-width: calc(
      100% - #{map-get($grid-column-gutter, small)}
    );
    @include breakpoint(medium) {
      max-width: calc(
        100% - #{map-get($grid-column-gutter, medium) + $help-popup-indent}
      );
    }
    h3 {
      width: 89%;
    }
  }

  &--top {
    .pointer {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid $help-popup-background;
      position: absolute;
      bottom: rem-calc(-14);
      left: $global-padding;
      z-index: 1007;
    }
  }

  &:focus {
    outline: unset;
  }
}

.form--input-group--password {
  .help-popup-button-strip {
    @include makeFullWidth(
      $small-breakpoint: true,
      $medium-breakpoint: true,
      $large-breakpoint: false
    );
    @include addGutterWidthNegativeMargins(
      $small-breakpoint: true,
      $medium-breakpoint: true,
      $large-breakpoint: false
    );
    margin-bottom: $global-margin * 1.5;
    @include breakpoint(large) {
      margin-bottom: $global-margin * 1.75;
    }
    border-top: 1px solid $secondary-lightest;
    @include breakpoint(large) {
      border-top: none;
      margin-bottom: $global-margin * 3;
    }
  }
}

.enter-code-popout {
  z-index: 103;
  @include positionElementHorizontalCenter();
  opacity: 0;
  background-color: $pale-gray;
  border: 1px solid $secondary-lighter;
  border-top: none;
  padding: rem-calc($global-padding * 0.5) rem-calc($global-padding * 0.75)
    rem-calc($global-padding * 0.5) rem-calc($global-padding * 0.75);
  border-radius: 0 0 $global-radius $global-radius;
  @include shadow(0px 2px 4px 0 rgba(0, 0, 0, 0.1));
  width: calc(100% - #{map-get($grid-column-gutter, small)});
  @include breakpoint(medium) {
    padding-bottom: rem-calc($global-padding * 0.5);
    width: calc(100% - #{map-get($grid-column-gutter, medium)});
  }
  transition: top 0.7s cubic-bezier(0.5, 0, 0.5, 1);
  hr {
    margin-bottom: rem-calc($global-margin * 0.5);
    @include shadow(none);
    width: calc(100% + 24px);
    margin-right: -($global-margin * 0.75);
    margin-left: -($global-margin * 0.75);
    border-color: $secondary-lightest;
  }
  &--code {
    padding-bottom: rem-calc($global-padding * 0.5);
    padding-top: rem-calc(28);
    max-width: rem-calc(320);
    @include breakpoint(medium) {
      padding-top: rem-calc(32);
      max-width: rem-calc(360);
    }
    form {
      padding: 0;
      margin: 0 auto;
      @include breakpoint(medium) {
        max-width: rem-calc(300);
      }
    }
  }
  &.reset-code-help-open {
    z-index: 107;
  }
  &--verification-code {
    transition: bottom 0.7s cubic-bezier(0.5, 0, 0.5, 1);
    @include shadow(0px 0px 8px 0 rgba(0, 0, 0, 0.2));
    z-index: 99;
    padding: 0;
    border-radius: $global-radius $global-radius 0 0;
    border: 1px solid $secondary-lighter;
    border-bottom: none;
    width: calc(100% - #{$global-margin * 2});
    @include breakpoint(medium) {
      max-width: rem-calc(420);
      width: auto;
    }
    left: rem-calc($global-margin);
    transform: none;
    &__top {
      background: $pale-green;
      border-bottom: 1px solid $secondary-lightest;
      border-radius: $global-radius $global-radius 0 0;
      padding: rem-calc($global-padding * 1.25);
      padding-bottom: rem-calc($global-padding * 0.75);
      p {
        margin-bottom: rem-calc($grid-column-gutter-single-small);
      }
      .callout--info {
        p {
          margin-bottom: 0;
          font-size: $small-font-size;
        }
      }
      .icon-on-left {
        padding-bottom: rem-calc($global-padding * 0.75);
        span {
          word-break: break-all;
        }
      }
    }
    &__code-input {
      margin: 0 auto;
      max-width: rem-calc(320);
      @include breakpoint(medium) {
        max-width: rem-calc(360);
      }
      .input-group {
        margin-bottom: rem-calc($global-margin * 0.25);
      }
      &[disabled] {
        opacity: 0.3;
      }
    }
  }
}

.slider-mask {
  clip-path: inset(0 0 0 0);
  width: 100%;
  bottom: 0;
  height: 0;
  transition-property: height;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  @include breakpoint(medium) {
    max-width: rem-calc(450);
  }
  &.show-slider-mask {
    height: rem-calc(600);
  }
  &--verification-code {
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    &.show-slider-mask {
      height: rem-calc(250);
    }
  }
  &--full-page-form-footer {
    &.show-slider-mask {
      height: rem-calc(150);
    }
    width: calc(100% - #{$grid-column-gutter-single-small * 2});
    @include breakpoint(medium) {
      width: 100%;
      max-width: rem-calc(300);
    }
    &--large {
      left: rem-calc($global-padding);
      @include breakpoint(medium) {
        left: rem-calc($global-padding * 1.5);
        max-width: rem-calc(500);
      }
    }
  }
}

.form--new-password {
  .help-popup {
    left: rem-calc($grid-column-gutter-single-small + 5px);
    max-width: calc(100% - #{$grid-column-gutter-single-medium * 2});
    @include breakpoint(medium) {
      left: rem-calc($grid-column-gutter-single-medium + 5px);
      max-width: calc(100% - #{$grid-column-gutter-single-small * 4});
    }
    &-button-strip {
      border-bottom: 1px solid $secondary-lightest;
      padding-top: rem-calc(2);
    }
  }
}

.form--code, .enter-code-popout--verification-code__code-input  {
    .help-popup {
      @include positionElementHorizontalCenter();
      z-index: 1;
  }
}

.enter-code-popout--verification-code__code-input  {
  .help-popup {
    max-width: calc(100% - #{$global-padding * 2});
    @include breakpoint(small-mobile) {
      max-width: rem-calc(300);
    }
  }
}

.form--code  {
  .help-popup {
    max-width: rem-calc(320);
  }
}




button,
.button {
  &.clear {
    font-weight: $global-weight-normal;
    color: $secondary-dark;
    @include shadow(none);
    background: none transparent;
    border: none;
    min-height: 100%;
    @include setInteractiveStyle($clear: true);
    &--inline {
      font-weight: $global-weight-bold;
      .icon {
        margin-left: rem-calc(2);
        margin-top: rem-calc(-3);
      }
    }
    &.open-in-new {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'><path d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' fill='%232a5065' fill-rule='nonzero'></path></svg>");
      background-repeat: no-repeat;
      background-position: right -1px;
      padding-right: rem-calc(22);
      @include setInteractiveStyle($not-inset: true);
      &:hover, &:focus-visible {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'><path d='M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' fill='%2385114f' fill-rule='nonzero'></path></svg>");
      }
    }
  }

  .mobile-register-sign-in {
    .clear {
      .icon {
        margin-top: rem-calc(-2);
        &--sign-in {
          margin-left: rem-calc(-4);
        }
        &--register {
          margin-left: rem-calc(-1);
        }
      }
    }
  }

  &.user-name {
    justify-content: flex-start;
  }
}

.sign-in__icons-list {
  .button {
    &.clear {
      align-items: flex-start;
    }
  }
}


.help-popup-button-strip {
  .button {
    @include setInteractiveStyle($icon-border: true);
    .icon {
      margin-right: rem-calc($grid-column-gutter-single-small);
    }
  }
}

.enter-code-popout--code {
  .button {
    span {
      line-height: 1;
    }
  }
}

nav {
  &.back-to-navigation {
    .button {
      &.clear {
        margin-top: rem-calc(2);
      }
    }
  }
}

.form--input-group__inner--small  {
  .clear {
    padding-left: rem-calc(6);
  }
}

.external-link {
  .icon {
    vertical-align: text-bottom;
    @include box(20px, 20px, false);
  }
  &--smaller {
    .icon {
      vertical-align: text-top;
      @include box(18px, 18px, false);
    }
  }
  @include setInteractiveStyle($link: true, $icon: true);
}
footer {
  z-index: 100;
}

.footer {
  &:not(&--unit-player-navigation, &--edit-details) {
    border-top: 1px solid $light-gray;
    padding-bottom: $global-padding*4;
    padding-top: rem-calc(1);

    @include breakpoint(medium down) {
      margin-bottom: rem-calc(75);
    }

    @include breakpoint(large) {
      padding-top: rem-calc(2);
    }
  }

  &__top {
    background: none $pale-gray;
    padding-top: $global-padding*1.25;
    border-bottom: 1px solid $light-gray;

  }

  &__learn-my-way-logo {
    max-width: rem-calc(70);
    margin-right: $global-margin*0.5;

    @include breakpoint(small-mobile) {
      max-width: rem-calc(85);
    }

    @include breakpoint(medium) {
      margin-right: $global-margin*2.25;
    }
  }

  &__good-things-logo {
    margin-left: $global-margin*0.5;
    max-width: rem-calc(165);
    opacity: 1;
    &:hover {
      opacity: 0.5;
      transition: opacity .3s;
    }
    @include breakpoint(small-mobile) {
      min-width: rem-calc(200);
    }

    @include breakpoint(medium) {
      margin-left: $global-margin*2.25;
    }
  }

  &__copyright {
    margin-top: rem-calc(6);
    @include breakpoint(medium) {
      margin-top: rem-calc($global-margin*0.5);
    }
  }

  ul {
    @include pipedListSeparator(1, 3, rem-calc(9), $secondary-light, 100%, $top: 2px);
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      // Why isn't this in Foundation??
      justify-content: left;
    }
  }

  &--unit-player-navigation {
    position: fixed;
    z-index: 100;
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    background-color: $pale-gray;
    border-top: 1px solid $dark-gray;
    @include shadow(inset 0 2px 2px $light-gray);
    min-height: rem-calc(map-get($player-footer-height, small));
    padding-top: rem-calc($grid-column-gutter-single-small);
    padding-bottom: rem-calc($grid-column-gutter-single-small);

    @include breakpoint (medium) {
      border-bottom-left-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
      padding-top: rem-calc($grid-column-gutter-single-medium);
      padding-bottom: rem-calc($grid-column-gutter-single-medium);
      min-height: rem-calc(map-get($player-footer-height, medium));
    }

    &.hide-for-mobile {
      display: none;

      @include breakpoint(large) {
        display: flex;
      }
    }
  }

  &--sign-in, &--modal-confirmation, &--modal-with-content {
    @include position(absolute, null, 0, 0, 0);
    width: 100%;
  }
  &--modal-confirmation, &--modal-with-content {
    border-top: 1px solid $light-gray;
  }
  &--sign-in {
    border-top: 1px solid $medium-gray;
    .button-container {
      padding: $grid-column-gutter-single-small;
      padding-bottom: rem-calc(12);
      @include breakpoint(medium) {
        padding: $grid-column-gutter-single-medium;
        padding-bottom: rem-calc(17);
      }
      background-color: $light-gray;
      border-top: 1px solid $medium-gray;
      .icon--loader {
        margin-left: rem-calc(5);
        margin-top: rem-calc(2);
        @include box(rem-calc(32), rem-calc(32), false);
      }
    }
  }
  &--modal-confirmation, &--modal-with-content {
    padding: rem-calc($global-padding * 0.75);
    @include breakpoint(medium) {
      padding: rem-calc($global-padding);
    }
  }

  &--edit-details {
    background-color: $light-gray;
    border-top: 1px solid $secondary-lighter;
    background-image: linear-gradient(180deg, white 1px, $light-gray 1px);
    padding: rem-calc($global-padding);
    flex-direction: column;
    z-index: 101;
    @include breakpoint(medium) {
      flex-direction: row;
      padding: rem-calc($global-padding * 2);
      border-bottom-left-radius: $global-radius;
      border-bottom-right-radius: $global-radius;
    }
  }
}

.mobile-register-sign-in {
  background: none $pale-gray;
  border-top: 1px solid $medium-gray;
  height: rem-calc(50);
  z-index: 200;

  ul {
    margin-bottom: 0;
    @include pipedListSeparator(1, 1, rem-calc(1), $medium-gray, 75%, $right: 0);

    li {
      height: rem-calc(50);
      padding-bottom: 0;
      width: 50%;
    }
  }
}

@mixin keyframe-animation($name, $attribute, $fromVal, $toVal, $zIndex) {
  @keyframes #{$name} {
    0% {
      #{$attribute}: $fromVal;
      z-index: $zIndex;
    }

    100% {
      #{$attribute}: $toVal;
      z-index: $zIndex;
    }
  }
}

@keyframes slide-down-fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0);
  }
}


.form {
  &--full-page {
    background: none $lightest-gray;
    border-top: 1px solid $secondary-lighter;
    border-bottom: 1px solid $secondary-lighter;
    @include makeFullWidth($medium-breakpoint: false);
    margin-left: -$grid-column-gutter-single-small;
    @include breakpoint(medium) {
      margin-left: 0;
      border: 1px solid $secondary-lighter;
      border-radius: $global-radius;
      max-width: rem-calc(680);
    }
    .form-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      border-radius: $global-radius;
    }
    .help-popup {
      h3 {
        padding-bottom: $global-padding * 0.25;
        font-size: $global-font-size;
        @include breakpoint(medium) {
          font-size: $large-font-size;
        }
      }
      &--local-support {
        width: calc(100% - #{map-get($grid-column-gutter, small)});
        left: 50%;
        transform: translateX(-50%);
        top: -1%;
        @include breakpoint(medium) {
          width: auto;
          max-width: rem-calc(340);
          right: -1%;
          transform: none;
          left: auto;
        }
      }
    }
    .help-popup-button-strip {
      border-radius: $global-radius $global-radius 0 0;
      border: 1px solid white;
      border-bottom-color: $lightest-gray;
      padding-right: rem-calc($grid-column-gutter-single-small);
      .button {
        .icon {
          margin-right: 0;
          margin-left: rem-calc($grid-column-gutter-single-small);
        }
      }
    }
    &--edit-details {
      .help-popup-button-strip {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $secondary-lightest;
        border-top: 1px solid $secondary-lightest;
        width: calc(100% + #{$global-padding * 2});
        margin-left: rem-calc(-$global-margin);
        margin-bottom: $global-margin * 2;
        @include breakpoint(medium) {
          width: calc(100% + #{$global-padding * 4});
          margin-left: rem-calc(-($global-margin * 2));
        }
        .button {
          .icon {
            margin-right: rem-calc($grid-column-gutter-single-small);
            margin-left: 0;
          }
        }
      }
      .button--icon-on-top-inline .button__inner {
        z-index: 0;
      }
    }
    fieldset,
    &__response {
      padding: $global-padding;
      @include breakpoint(medium) {
        padding: $global-padding * 2;
      }
      h3:not(.help-popup h3) {
        color: $secondary-dark;
        margin-bottom: $global-margin;
        font-size: rem-calc(20);
        @include breakpoint(medium) {
          font-size: rem-calc(22);
        }
      }
    }
    fieldset {
      .form-item {
        margin-bottom: $global-padding * 1.5;
        @include breakpoint(medium) {
          margin-bottom: $global-padding * 2;
        }
        input[type='text'],
        input[type='email'],
        input[type='password'],
        textarea {
          max-width: rem-calc($input-full-page-form-max-width);
        }
        textarea {
          padding-top: rem-calc(14);
        }
        .radio-button {
          margin-bottom: rem-calc(6);
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
        }
        &__wrapper {
          max-width: rem-calc($input-full-page-form-max-width);
          input[type='text'],
          input[type='password'],
          textarea {
            max-width: none;
          }
        }
      }
      h3:not(.form--full-page .help-popup h3) {
        margin-bottom: $global-margin*0.5;
      }
      .radio-button input[type='radio'] + label {
        min-height: rem-calc(40);
        @include breakpoint(medium) {
          min-height: rem-calc(48);
        }
      }
      button[type='submit'] {
        align-self: flex-end;
        min-width: rem-calc(268);
        .icon {
          &--loader {
            margin-left: rem-calc(6);
          }
        }
        @include breakpoint(medium) {
          margin-top: rem-calc(22);
          min-height: rem-calc(50);
        }
      }
    }
    &__response {
      @include breakpoint(medium) {
        padding-top: $global-padding * 1.5;
      }
      p,
      li {
        @include breakpoint(medium) {
          font-size: $large-font-size;
        }
      }
      h3 {
        .icon {
          @include box(32px, 32px, false);
          margin: rem-calc(-4) 0 0 rem-calc(5);
        }
      }
      h4 {
        color: $secondary-dark;
        margin: 0 0 $global-margin 0;
        font-size: rem-calc(18);
        @include breakpoint(medium) {
          font-size: rem-calc(20);
        }
      }
    }
    .section {
      &--full-page-form {
        margin-top: 0;
        margin-bottom: rem-calc($global-margin * 1.5);
        width: calc(100% + #{$global-padding * 2}) !important;
        margin-left: rem-calc(-$global-margin) !important;
        @include breakpoint(medium) {
          width: calc(100% + #{$global-padding * 4}) !important;
          margin-left: rem-calc(-$global-margin * 2) !important;
          margin-bottom: rem-calc($global-margin * 2);
        }
      }
      &--divider {
        @include breakpoint(medium) {
          margin-bottom: rem-calc($global-margin * 1.5);
        }
      }
    }
  }
}

button,
.button {
  &.primary {
    color: white;
    background: none $primary;
    border-color: $primary;
    font-size: $large-font-size;
    min-width: rem-calc(300);
    max-width: none;
    min-height: rem-calc(43);
    text-align: center;
    @include shadow(0 2px 0 $primary-darkest);
    @include breakpoint(medium) {
      max-width: rem-calc(300);
    }
    @include setInteractiveStyle($primary: true);
    &.button--icon-on-right {
      @include setInteractiveStyle($primary: true, $icon: true);
    }
    &.hollow {
      @include breakpoint(medium) {
        padding: rem-calc($global-padding*0.5)
          map-get($grid-margin-gutters, medium) rem-calc($global-padding*0.5)
          map-get($grid-margin-gutters, medium);
      }
      @include setInteractiveStyle($primary-hollow: true);
    }
  }
}

.sign-in {
  .button.primary {
    font-size: 107.5%;
    min-height: rem-calc(52);
    line-height: $button-text-lineheight;
    padding-left: rem-calc($grid-column-gutter-single-small);
    padding-right: rem-calc($grid-column-gutter-single-small);
    @include breakpoint(medium) {
      padding-left: rem-calc($grid-column-gutter-single-medium);
      padding-right: rem-calc($grid-column-gutter-single-medium);
      font-size: 115%;
      min-height: rem-calc(55);
    }
  }
}

.footer {
  &--modal-with-content {
    .button {
      &.primary {
        font-size: $global-font-size;
        @include breakpoint(medium) {
          font-size: $large-font-size;
        }
        @include breakpoint(medium down) {
          min-height: rem-calc(36);
        }
      }
    }
  }
  &--modal-confirmation {
    .button {
      &.primary {
        min-width: calc(50% - 10px);
        max-width: calc(50% - 10px);
      }
    }
  }

  &--edit-details {
    .button {
      &.primary {
        margin-bottom: $global-margin;
        min-width: auto;
        @include breakpoint(medium) {
          min-width: 18.75rem;
          margin-bottom: 0;
          margin-right: $global-margin;
        }
        @include setInteractiveStyle($primary: true, $icon: true);
        span {
          padding-top: rem-calc(2);
        }
      }
      &--icon-on-left {
        .icon--loader {
          margin-top: 1px
        }
      }
    }
  }
}

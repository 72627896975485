.header {
  border-bottom: 1px solid $secondary-lightest;
  @include shadow(
    0 2px 4px 0 rgba(0, 0, 0, 0.05)
  ); // it's bloody .10 in Sketch!!!
  z-index: 500;
  position: relative;

  ul {
    li {
      &.inactive {
        opacity: 0.3;
      }
    }
  }

  &__top {
    background: none $pale-gray;
    border-bottom: 1px solid $medium-gray;

    min-height: rem-calc(40);
    @include breakpoint(large) {
      min-height: rem-calc(45);
    }

    &--no-user-links {
      min-height: rem-calc(32);
      @include breakpoint(large) {
        min-height: rem-calc(40);
      }
    }

    ul {
      margin-bottom: 0;
      min-height: rem-calc(40);
      @include breakpoint(large) {
        min-height: rem-calc(45);
      }

      li {
        padding-bottom: 0;

        &.icon-on-left {
          font-size: $small-font-size;

          @include breakpoint(large) {
            font-size: $global-font-size;
          }
        }
      }

      @include pipedListSeparator(1, 1, rem-calc(22), $secondary-light, 50%);
    }
  }

  &__bottom {
    position: relative;
    min-height: rem-calc(91);
    @include breakpoint(large) {
      max-height: rem-calc(115);
    }

    li {
      a.active, a.active .icon-text {
        color: $primary-dark;
        .icon {
          fill: $primary-dark;
        }
        &:link {
          text-decoration: none;
        }
      }

    }
  }

  &__logo {
    position: relative;
    width: rem-calc(100);
    height: rem-calc(91);
    @include breakpoint(small-mobile) {
      width: rem-calc(125);
    }
    @include breakpoint(medium) {
      width: rem-calc(144);
      height: rem-calc(91);
    }
    @include breakpoint(large) {
      width: rem-calc(178);
      height: rem-calc(110);
    }
    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: rem-calc(10);
      left: 0;
      @include breakpoint(small-mobile) {
        top: rem-calc(-8);
      }
      @include breakpoint(medium) {
        top: rem-calc(-24);
      }
      @include breakpoint(large) {
        top: rem-calc(-30);
      }
    }
  }
}

.unit-player-header {
  z-index: 100;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  background-color: $pale-gray;
  border-top: 1px solid white;
  border-bottom: 1px solid $secondary-lightest;

  @include breakpoint(medium) {
    border-top-right-radius: $reveal-radius;
    border-top-left-radius: $reveal-radius;
    border-top: none;
  }

  &__content {
    min-height: rem-calc(map-get($player-header-height, small));
    padding-left: rem-calc($grid-column-gutter-single-small);

    @include breakpoint(medium) {
      min-height: rem-calc(map-get($player-header-height, medium));
      padding-left: rem-calc($grid-column-gutter-single-medium);
    }
  }

  &--with-progress-bar {
    .unit-player-header__content {
      min-height: rem-calc(map-get($player-header-height-with-progress, small));

      @include breakpoint(medium) {
        min-height: rem-calc(
          map-get($player-header-height-with-progress, medium)
        );
      }

      margin-bottom: 0;
      border-bottom: none;
    }

    .unit-player-header__progress {
      .progress {
        height: rem-calc(map-get($progress-bar-height, small));

        @include breakpoint(medium) {
          height: rem-calc(map-get($progress-bar-height, medium));
        }

        margin-bottom: 0;

        &--outer {
          @include shadow(
            inset 0 1px 2px 0 $medium-gray
          ); // This doesn't match the design system - not sure why, it's was way too dark with $darkest-gray. Sketch and CSS inevitably diverge with how the shadow works.
          border-top: 1px solid $dark-gray;
          border-bottom: 1px solid $dark-gray;
          &[aria-valuenow='100'] {
            .progress--inner {
              border-radius: 0;
            }
          }
        }
        &--inner {
          height: 100%;
          border-radius: 0 $global-radius * 2 $global-radius * 2 0;
          background: none $success;
          @include shadow(inset 0 -4px 4px 0 $success-darker);
          transition: width 0.3s;
        }
      }
    }
  }

  &--small-mobile-slide-up {
    top: rem-calc(-94);
    transition: top 0.35s cubic-bezier(0.84, 0, 0.17, 1);

    @include breakpoint(medium) {
      top: 0;
      transition: none;
    }
  }
}

.sign-in-header {
  position: relative;
  z-index: 106;
  &__content {
    min-height: rem-calc(map-get($sign-in-header-height, small));
    @include breakpoint(medium) {
      min-height: rem-calc(map-get($sign-in-header-height, medium));
    }
  }
}

.home-container {
  z-index: 499;
  max-width: 100%;

  section {
    &:not(section:first-of-type) {

      @include breakpoint(medium) {
        padding-top: $global-margin*2;
        padding-bottom: $global-padding*2; // Why aren't these responsive padding classes in Foundation??

        p {
          &:last-of-type {
            margin-bottom: rem-calc($global-margin*1.5);
          }
        }
      }

      @include breakpoint(large) {
        padding-top: $global-margin*2.5;
        padding-bottom: $global-padding*2.5; // Why aren't these responsive padding classes in Foundation??
      }
    }

    &:first-of-type {
      @include breakpoint(medium) {
        background-image: url(../../../../images/digital-bg-gradient.png);
        background-repeat: repeat-x;
        background-position-y: bottom;
        background-size: contain;

        p {
          margin-bottom: rem-calc($global-margin*1.5);
        }
      }

      .cell {
        &:first-of-type {
           // Why aren't these responsive padding classes in Foundation??
          ul.link-button-list {
            @include breakpoint(medium) {
              padding-bottom: $global-padding;
            }
            li {
              margin-bottom: $global-margin*.75;
              padding-bottom: 0;
              @include breakpoint(medium) {
                background: none white;
                max-width: rem-calc(300);
              }
              &.separator {
                display: none;
                @include breakpoint(medium) {
                  display: flex;
                  height: rem-calc($global-margin*0.25);
                  margin: 0;
                  background: none transparent;
                  border-bottom: 1px solid $medium-gray;
                }
              }
              &:last-of-type {
                padding-bottom: 0;
                margin-bottom: $global-margin;
                @include breakpoint(medium) {
                  margin-top: $global-margin*0.75;
                }
              }
            }
          }
        }

        .primary.hollow {
          max-width: none;
        }

        hr {
          max-width: none;
        }
      }
    }

    &:nth-child(2) {
      @include shadow(0 2px 4px 0 rgba(0, 0, 0, .05));
      z-index: 999999;
    }

    &:last-of-type {
      position: relative;
      background-color: rgba(255, 255, 255, 0.87);
      &:before, &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      &:before {
        background-image: url(../../../../images/seamless-digital-pattern.png);
        background-repeat: repeat-x;
        background-size: contain;
      }
      &:after {
        background: linear-gradient(white 35%, rgba(255, 255, 255, 0.4) 100%);
      }
    }

    &.border-bottom-light {
      border-bottom: 1px solid $light-gray;
    }
  }

  hr {
    display: none !important;

    @include breakpoint(medium) {
      display: flex !important;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      max-width: rem-calc(300);
    }
  }
}
button,
.button {
  font-size: $global-font-size;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: $header-font-weight;
  border-radius: $button-radius;
  border-width: 1px;
  border-style: solid;
  width: auto;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  line-height: $button-lineheight;
  .icon {
    transition: fill 0.3s, border-color 0.3s;
  }
  cursor: pointer;
  &.medium-down-expanded,
  &.expanded {
    display: flex !important;
  }
  &.expanded {
    width: 100%!important;
    max-width: none!important;
    min-width: auto!important;
  }
  &.medium-down-expanded {
    width: 100%;
    @include breakpoint(medium) {
      width: auto;
    }
  }
  &.with-shadow {
    span {
      padding-top: rem-calc(3);
    }
  }
  span {
    vertical-align: middle;
    span {
      vertical-align: baseline;
    }
  }
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .icon-text {
    &.active {
      color: $primary-dark;
    }
  }
  &.active {
    cursor: default;
  }
}

@import 'clear';
@import 'icon-buttons';
@import 'light';
@import 'primary';
@import 'hollow';
@import 'browse';
@import 'secondary';
@import 'skip-to-content';
@import 'small-large';
@import 'square';
@import 'accordion';

.input-group {
  .input-group-button {
    width: auto;
    height: auto;
    button {
      height: 100%;
      border-radius: 0 $button-radius $button-radius 0;
      color: white;
      .icon {
        @include box(rem-calc(22), rem-calc(22), false);
      }
      @include breakpoint(medium) {
        font-size: $large-font-size;
        .icon {
          @include box(rem-calc(24), rem-calc(24), false);
        }
      }
    }
  }
}

.footer--unit-player-navigation {
  .button {
    max-width: 48%;
    @include breakpoint(medium) {
      padding-top: rem-calc(1);
    }
  }
}

.enter-code-popout {
  .button {
    &.clear:not(.button--icon-on-top, .close-button), &.hollow {
      margin: 0 auto;
      padding: rem-calc($grid-column-gutter-single-small);
      min-width: rem-calc(240);
      line-height: 1.2;
      @include breakpoint(large-mobile) {
        min-width: rem-calc(300);
      }
    }
    &.clear:not(.button--icon-on-top, .close-button) {
      min-height: rem-calc(36);
    }
  }

  &--verification-code {
    .button {
      &.clear:not(.close-button) {
        min-width: auto!important;
        padding-left: $grid-column-gutter-single-small * 0.5;
        margin: 0;
      }
    }
  }
  &--code {
    .button {
      &.clear:not(.button--icon-on-top) {
        min-width: 100%;
      }
    }
  }
}

.sign-in--password {
  .input-group--with-link {
    + .button.clear {
      min-height: rem-calc(38);
    }
  }
}

#ccc-button-holder, .ccc-notify-buttons {
  display: flex;
}

#ccc {
  .ccc-notify-button {
    border-width: 1px!important;
    span {
      font-weight: $global-weight-bold;
    }
    &:hover {
      background-color: white!important;
      span {
        color: $secondary-dark!important;
      }
    }
    &.ccc-link {
      &.ccc-accept-button, &.ccc-reject-button {
        span {
          color: $secondary-dark!important;
          
        }
        &:hover {
          background-color: transparent!important;
          span {
            color: white!important;
            background-color: transparent!important;
          }
        }
      }
    }
  }
  &-close {
    svg {
      width: rem-calc(44);
      height: rem-calc(44);
    }
    border: 1px solid $help-popup-background!important;
    right: rem-calc(8)!important;
    top: rem-calc(8)!important;
    &:hover {
      border-color: white!important;
    }
  }

} 

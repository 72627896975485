button,
.button {
  &.browse {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    font-size: $large-font-size;
    line-height: 1.1875;
    min-height: rem-calc(50);
    text-align: left;
    justify-content: space-between;
    padding: rem-calc($global-padding*0.5) 0 rem-calc($global-padding*0.5)
      rem-calc($grid-column-gutter-single-small);
    .icon {
      margin-right: rem-calc($global-padding * 0.5);
      @include box(rem-calc(22), rem-calc(22), false);
      &--material {
        margin-right: 0;
        @include box(rem-calc(36), rem-calc(36), false);
        min-width: rem-calc(36);
      }
    }
    @include breakpoint(medium) {
      padding-left: rem-calc($grid-column-gutter-single-medium);
      border-radius: $global-radius;
    }
    @include breakpoint(large) {
      min-height: rem-calc(58);
      font-size: $largest-font-size;
      .icon {
        margin-right: rem-calc($global-padding * 0.75);
        @include box(rem-calc(24), rem-calc(24), false);
        &--material {
          margin-right: 0;
          @include box(rem-calc(40), rem-calc(40), false);
        }
      }
    }
    &--subject {
      color: $primary-dark;
      border-color: $primary-dark;
      background-image: linear-gradient(180deg, white 30%, $pale-gray 100%);
      @include setInteractiveStyle($browse-subject: true);
      @include breakpoint(medium) {
        border: 1px solid $primary-dark;
      }
    }
    &--topic {
      background-image: none;
      background-color: $pale-gray;
      border-color: $medium-gray;
      color: $secondary-dark;
      font-weight: $global-weight-normal;
      font-size: $global-font-size;
      @include shadow(inset 0 2px 0 white);
      @include setInteractiveStyle($browse-topic: true);
      @include breakpoint(medium) {
        border: 1px solid $medium-gray;
      }
      @include breakpoint(large) {
        font-size: $large-font-size;
      }
      &-locked {
        border: 1px solid $medium-gray;
        color: $faded-gray;
        background-color: white;
        pointer-events: none;
        @include shadow(none);
        .icon {
          &--lock {
            @include box(24px, 24px, false);
            @include breakpoint(medium) {
              @include box(30px, 30px, false);
            }
            fill: $dark-gray;
          }
        }
      }
    }
    &--my-learning {
      background-image: linear-gradient(180deg, white 90%, rgba(158, 196, 209, 0.5) 100%);
      color: $secondary-dark;
      border-color: $secondary-dark;
      @include shadow(none);
      @include setInteractiveStyle($browse-my-learning: true);
      @include breakpoint(medium) {
        border: 1px solid $secondary-dark;
        min-height: rem-calc(72);
      }
    }
    &__print {
      justify-content: flex-end;
      @include breakpoint(medium) {
        font-size: $large-font-size;
      }
    }
  }
}
.reveal {
  display: block;
  position: absolute;
  @include shadow(0 0 20px rgba(0, 0, 0, 0.5));
  @include breakpoint(medium) {
    @include shadow(0 0 50px rgba(0, 0, 0, 0.5));
  }
  &-overlay {
    display: block;
    &--mobile-menu {
      display: none;
      z-index: 1;
      opacity: 0.4;
    }
    &--modal-confirmation, &--mobile-menu, &--modal-with-content {
      background-color: black;
    }
    &--modal-confirmation, &--modal-with-content {
      opacity: 0.75;
    }
  }
  padding-bottom: rem-calc(81);
  overflow-y: hidden;

  &.modal-confirmation, &.sign-in, &.modal-with-content {
    @include absolute-center;
    width: calc(100% - #{map-get($grid-column-gutter, small)});
    bottom: auto;
    max-width: none;
    height: auto;
    min-height: rem-calc(380);
    margin-left: 0;
    border: 0;
    border-radius: $reveal-radius;
    padding-left: rem-calc($grid-column-gutter-single-small);
    padding-right: rem-calc($grid-column-gutter-single-small);
    @include breakpoint(large-mobile) {
      width: rem-calc(391);
    }
    @include breakpoint(medium) {
      min-height: rem-calc(432);
      padding-left: rem-calc($grid-column-gutter-single-medium);
      padding-right: rem-calc($grid-column-gutter-single-medium);
      width: rem-calc(450);
    }
  }

  &.modal-confirmation {
    min-height: rem-calc(280);
  }

  &.sign-in {
    min-height: rem-calc(450);
    @include breakpoint(medium) {
      min-height: rem-calc(500);
    }
    padding: 0;
  }
  &.modal-confirmation {
    padding: rem-calc($grid-column-gutter-single-small * 2);
    h2 {
      font-size: rem-calc(20);
      line-height: 1.2;
      color: $secondary-dark;
      @include breakpoint(medium) {
        font-size: rem-calc(22);
      }
    }
  }
  &.modal-with-content {
    padding: 0;
    padding-left: rem-calc($grid-column-gutter-single-small * 1.2);
    height: calc(100% - #{map-get($grid-column-gutter, small)});
    @include breakpoint(medium) {
      padding-left: rem-calc($grid-column-gutter-single-medium * 1.2);
      height: calc(100% - #{map-get($grid-column-gutter, medium)});
      width: calc(100% - #{map-get($grid-column-gutter, medium)});
    }
  }
  &.registration,
  &.unit-player {
    padding: 0;
    border: none;
    top: 0;
    width: 100%;
    max-width: map-get($breakpoints, medium);
    height: 100%;
    transform: translateY(
      0
    ); // provide context for position fixed - need to check this works in IE11
    border-radius: 0;
    @include breakpoint(medium) {
      border-radius: $reveal-radius;
      height: calc(100% - 40px);
      max-height: map-get($breakpoints, tablet-portrait);
      max-width: map-get($breakpoints, large);
      @include absolute-center;
      width: calc(100% - #{map-get($grid-margin-gutters, medium)}* 2);
    }
  }
  &.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: none;
    max-height: 100vh;
    max-width: 100vw;
    border-radius: 0;
    main {
      padding: 0;
      margin: 0;
    }
  }
}

.modal-with-content {
  &__content {
    overflow-y: auto;
    padding: rem-calc($grid-column-gutter-single-small * 1.8) rem-calc($grid-column-gutter-single-small * 1.2) rem-calc($grid-column-gutter-single-small * 1.2) 0;
    max-height: calc(100% - #{map-get($modal-with-content-footer-height, small)});
    @include breakpoint(medium) {
      padding: rem-calc($global-padding * 1.5) rem-calc($global-padding * 1.5) rem-calc($global-padding * 1.5) 0;
      max-height: calc(100% - #{map-get($modal-with-content-footer-height, medium)});
    }
  }
}
.progress-bar {
  position: fixed;
  z-index: 101;
  border-bottom: 1px solid $secondary-lightest;
  height: rem-calc(map-get($progress-bar-height, small));
  top: rem-calc(map-get($player-header-height-with-progress, small));
  width: 100%;

  @include breakpoint(medium) {
    height: rem-calc(map-get($progress-bar-height, medium));
    top: rem-calc(map-get($player-header-height-with-progress, medium));
  }

  margin-bottom: 0;

  &.hide-for-mobile {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__outer {
    height: 100%;
    background-color: white;
    @include shadow(inset 0 1px 2px 0 $medium-gray);
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    &[aria-valuenow='100'] {
      .progress-bar__inner {
        border-radius: 0;
      }
    }
  }
  &__inner {
    height: 100%;
    border-radius: 0 $global-radius * 2 $global-radius * 2 0;
    background: none $success;
    @include shadow(inset 0 -4px 4px 0 $success-darker);
    transition: width 0.3s;
  }
}

.browse--my-learning {
  .progress-bar {
    position: relative;
    border-bottom: none;
    top: 0;
    height: auto;
    @include makeFullWidth($gutter: true, $large-breakpoint: true);
    margin: 0 auto;
    &__outer {
      height: rem-calc(map-get($progress-bar-height, small));
      @include breakpoint(medium) {
        height: rem-calc(map-get($progress-bar-height, medium));
      }
      border: 1px solid $secondary-lighter;
      @include shadow(inset 0 2px 2px 0 $light-gray);
      border-top: none;
      background-color: white;
    }
    &__inner {
      width: 0;
    }
    &-info {
      background: none $pale-blue;
      font-weight: 500;
      font-size: $small-font-size;
      min-height: rem-calc(34);
      border: 1px solid $secondary-lighter;
      border-top: none;
      border-radius: 0 0 $global-radius $global-radius;
      padding-right: $grid-column-gutter-single-small;
      @include breakpoint(medium) {
        padding-right: $grid-column-gutter-single-small * 0.75;
        min-height: rem-calc(38);
        font-size: $global-font-size;
      }
    }
  }
}

.padding {
  &-0 {
    padding: 0!important;
  }
  &-top {
    &-0 {
      padding-top: 0!important;
      &-5 {
        padding-top: rem-calc($global-padding * 0.5)!important;
      }
      &-25 {
        padding-top: rem-calc($global-padding * 0.25)!important;
      }
    }
    &-2 {
      padding-top: $global-padding * 2!important;
    }
    &-1 {
      padding-top: $global-padding!important;
      &-5 {
        padding-top: rem-calc($global-padding * 1.5)!important;
      }
    }
  }
  &-bottom {
    &-0 {
      padding-bottom: 0!important;
      &-5 {
        padding-bottom: rem-calc($global-padding * 0.5)!important;
      }
    }
    &-1 {
      padding-bottom: $global-padding!important;
      &-5 {
        padding-bottom: $global-padding*1.5!important;
      }
    }
    &-2 {
      padding-bottom: $global-padding * 2!important;
    }
  }

  &-left {
    &-0 {
      padding-left: 0!important;
    }
    &-1 {
      padding-left: $global-padding!important;
    }
  }

  &-right {
    &-0 {
      padding-right: 0!important;
    }
    &-1 {
      padding-right: $global-padding!important;
    }
  }

  &-small-bottom-0 {
    @include breakpoint(large-mobile down) {
      padding-bottom: 0!important;
    }
  }

  &-medium-left-1 {
    @include breakpoint(medium) {
      padding-left: rem-calc($grid-column-gutter-single-medium);
    }
  }
  &-tablet-portrait-left-2 {
    @include breakpoint(tablet-portrait) {
      padding-left: rem-calc($grid-column-gutter-single-medium*2);
    }
  }
}

.margin {
  &-0 {
    margin: 0!important;
  }
  &-left {
    &-0 {
      margin-left: 0!important;
      &-5 {
        margin-left: rem-calc($global-margin * 0.5)!important;
      }
    }
    &-1 {
      &-5 {
        margin-left: rem-calc($global-margin * 1.5)!important;
      }
    }
    &-2 {
      margin-left: $global-margin * 2!important;
    }
  }
  &-right {
    &-0 {
      margin-right: 0!important;
      &-5 {
        margin-right: rem-calc($global-margin * 0.5)!important;
      }
    }
    &-1 {
      margin-right: rem-calc($global-margin)!important;
    }
  }
  &-top {
    &-0 {
      margin-top: 0!important;
      &-5 {
        margin-top: rem-calc($global-margin * 0.5)!important;
      }
    }
    &-1 {
      margin-top: $global-margin!important;
      &-5 {
        margin-top: $global-margin * 1.5!important;
      }
    }
    &-2 {
      margin-top: $global-margin * 2!important;
    }
  }
  &-bottom {
    &-0 {
      margin-bottom: 0!important;
      &-5 {
        margin-bottom: $global-margin * 0.5!important;
      }
    }
    &-1 {
      margin-bottom: $global-margin!important;
      &-5 {
        margin-bottom: $global-margin * 1.5!important;
      }
    }
    &-2 {
      margin-bottom: $global-margin * 2!important;
    }
  }
}

.gutter {
  &-left {
    &-small {
      padding-left: $grid-column-gutter-single-small!important;
      @include breakpoint(medium) {
        padding-left: 0!important;
      }
    }
  }

  &-right {
    &-small {
      padding-right: $grid-column-gutter-single-small!important;
      @include breakpoint(medium) {
        padding-right: 0!important;
      }
    }

  }
}



.position {
  &-relative {
    position: relative!important;
  }
  &-absolute {
    position: absolute!important;
  }
  &-static {
    position: static!important;
  }
  &-fixed {
    &-bottom {
      position: fixed!important;
      bottom: 0!important;
      width: 100%!important;
      z-index: 999999;
    }
  }
}

hr {
  box-sizing: inherit!important;
  border-top: 1px solid $light-gray;
  border-bottom: none;
  @include shadow(none);
}

.width {
  &-100 {
    width: 100%!important;
  }
  &-auto {
    width: auto!important;
    min-width: auto!important;
  }
}
.height {
  &-100 {
    height: 100%!important;
  }
  &-auto {
    height: auto!important;
    min-height: auto!important;
  }
}

.centred {
  margin: 0 auto!important;
}

.no-shadow {
  box-shadow: none!important;
}

.no-border {
  border: none!important;
  &-bottom {
    border-bottom: none!important;
  }
}
.border {
  &-transparent {
    border-color: transparent!important;
  }
}

.no-max-width {
  max-width: none!important;
}

.show {
  display: block!important;
}

.text {
  &-secondary-dark {
    color: $secondary-dark!important;
  }
}

.big-shadow {
  box-shadow: 0 0 15px rgb(0 0 0 / 50%)!important;
  @include breakpoint(medium) {
    box-shadow: 0 0 30px rgb(0 0 0 / 50%)!important;
  }
}

.inline {
  display: inline!important;
  &-block {
    display: inline-block!important;
  }
  &-flex {
    display: inline-flex!important;
  }
}

.block {
  display: block!important;
}

.overflow {
  &-hidden {
    overflow: hidden!important
  }
}

.justify-content-start {
  justify-content: flex-start!important;
}

.hidden {
  visibility: hidden !important;
}

.font {
  &-large {
    font-size: $large-font-size!important;
  }
}

@media print {
  p,
  body,
  h1,
  h2,
  .button,
  .browse--my-learning {
    color: $dark-gray !important;
  }
  p {
    &.lead {
      display: none;
    }
  }
  .icon {
    fill: $dark-gray !important;
  }
  .header {
    &__top {
      background: white;
      ul {
        li {
          &:nth-child(1) {
            padding-right: none;
            &:after {
              border: none;
            }
          }
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
    &__bottom {
      nav {
        display: none;
      }
    }
  }
  .breadcrumb {
    display: none;
  }
  .button {
    &.browse--my-learning, &.browse--topic {
      min-height: rem-calc(58);
      border-color: $dark-gray !important;
      font-size: $large-font-size;
      .icon {
        display: none;
      }
    }
  }
  .browse--my-learning, .browse--topics {
    &__header-wrapper {
      .button {
        display: none;
      }
    }
  }
  .progress-bar {
    &__outer {
      display: none;
    }
    &-info {
      background: white;
    }
  }

  .footer {
    & > .grid-container {
      display: none;
    }
  }

  img {
    filter: grayscale(100%);
  }
}
.sign-in {
  hr {
    margin: 0;
  }

  &--password {
    hr {
      width: 100%;
      border-color: $secondary-lighter;
      @include shadow(0px 2px 4px 0 rgba(0,0,0, 0.25));
      position: relative;
      z-index: 104;
    }

  }

  &__icons-list {
    position: relative;
    z-index: 105;
    background: none white;
    &.no-bullet {
      padding: $global-padding rem-calc($grid-column-gutter-single-small);
      @include breakpoint(medium) {
        padding: $global-padding rem-calc($grid-column-gutter-single-medium);
      }
    }

    li.icon-on-left {
      min-height: rem-calc(25);
      display: flex;
      align-items: center;
      padding-bottom: $global-padding * 0.75;
      @include breakpoint(medium) {
        min-height: rem-calc(30);
      }
      .icon {
        min-width: rem-calc(24);
        align-self: flex-start;
        margin-left: -1px;
        &--email {
          margin-top: rem-calc(-1);
        }
        &--mobile {
          margin-top: rem-calc(-2);
          margin-left: rem-calc(-4);
          margin-right: rem-calc(7);
          &-learner-password {
            @include breakpoint(medium) {
              margin-top: 0;
            }
          }
        }
        &--checkmark {
          margin-top: rem-calc(-1);
          @include box(22px, 22px, false);
        }
      }
      span {
        word-break: break-word;
        line-height: $header-lineheight;
        align-self: flex-start;
      }
    }

    li {
      .button--icon-on-left {
        &.clear {
          .icon {
            width: rem-calc(22);
            height: rem-calc(22);
            margin-right: rem-calc(7);
            margin-left: -1px;
            &--arrow-left {
              margin-top: rem-calc(-2);
              margin-left: 0;
              margin-right: rem-calc(6);
            }
          }
        }
      }
    }

  }
}

@use "sass:math";

h1 {
  color: $secondary-light;
  margin-bottom: $global-margin;
  font-size: $global-font-size*1.625;
  line-height: 1.1499999975;
  margin-top: $global-margin*1.15;

  @include breakpoint(medium) {
    margin-top: $global-margin*1.5;
  }
  @include breakpoint(large) {
    line-height: $header-lineheight;
  }
  @include breakpoint(medium) {
    font-size: $global-font-size*2;
  }
  &.secondary {
    @include breakpoint(large) {
      margin-top: rem-calc(24);
    }
  }
  &#ccc-notify-title {
    font-size: $global-font-size*1.25;
    @include breakpoint(medium) {
      font-size: $global-font-size*1.5;
      margin-bottom: $global-margin / 2;
    }
    
  }
}



h2 {
  color: $secondary-light;
  margin-bottom: $global-margin;
  font-size: $global-font-size*1.375;
  line-height: $header-lineheight;
  margin-top:0;
  @include breakpoint(medium) {
    font-size: $global-font-size*1.625;
  }
}

.browse {
  &--my-learning {
    h1 {
      @include breakpoint(small only) {
        font-size: rem-calc(22);
      }
    }
    h2 {
      @include breakpoint(small only) {
        font-size: $large-font-size;
      }
      width: 80%;
    }

  }
}

h3 {
  font-size: rem-calc(17);
  @include breakpoint(medium) {
    font-size: rem-calc(20);
  }
  color: $secondary-light;
  margin-top: 0;
}

h1, h2, h3, h4, strong, dt {
  font-weight: $global-weight-bold;
}

p {
  margin-bottom: $global-margin * 0.7; // account for Sketch's line height weirdness
  text-rendering: optimizeLegibility;
  margin-top: 0;

  @include breakpoint(medium) {
    margin-bottom: $global-margin;
  }

  &.lead {
    font-size: $global-font-size;
    width: 95%;

    @include breakpoint(medium) {
      font-size: $large-font-size;
    }
    .icon--lock {
      vertical-align: bottom;
      fill: $dark-gray;
      @include box(24px, 24px, false);
      @include breakpoint(medium) {
        @include box(28px, 28px, false);
      }
    }
  }
}



.text-uppercase {
  text-transform: uppercase!important;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none!important;
}

em {
  margin: 0 rem-calc(2) 0 rem-calc(-1);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.medium-text-right {
  @include breakpoint(medium) {
    text-align: right;
  }
}
.normal-text {
  font-size: $global-font-size!important;
  p {
    font-size: $global-font-size!important;
  }
  &-weight {
    font-weight: 300!important;
  }
}

.large-text {
  font-size: $large-font-size!important;
  &-medium {
    @include breakpoint(medium) {
      font-size: $large-font-size!important;
    }
  }
}

.smallest-text {
  font-size: $smallest-font-size!important;
  @include breakpoint(medium) {
    font-size: $small-font-size!important;
  }
}

.small-text {
  &-medium {
    @include breakpoint(medium) {
      font-size: $small-font-size!important;
    }
  }
}

.bold-text {
  font-weight: $global-weight-bold!important;
}

ul,
ol {
  padding-left: 0;
  li {
    padding-bottom: rem-calc(6);
    position: relative;

    &:last-child {
      padding-bottom: 0;
    }

    @include breakpoint(medium) {
      padding-bottom: $global-padding * 0.625;
    }
  }
}

ol {
  &.no-bullet {
    line-height: $header-lineheight;
    margin-left: 0;
    list-style: none;

    @include breakpoint(medium) {
      margin-bottom: $global-margin * 1.5;
    }
  }

  &:not(.no-bullet) {
    margin-left: $grid-column-gutter-single-small * 2;
    counter-reset: item;
    list-style: none;

    li {
      padding-left: rem-calc(math.div($grid-column-gutter-single-small, 1.5));

      &:before {
        content: counter(item) '. ';
        counter-increment: item;
        position: absolute;
        z-index: -1;
        margin-left: rem-calc(-($grid-column-gutter-single-small * 2.5));
      }
    }
  }
}

ul {
  margin: 0 0 $global-margin 0;
  @include breakpoint(medium) {
    margin-left: rem-calc(2);
  }

  li {
    margin-left: rem-calc($grid-column-gutter-single-small * 2);
      &::marker {
        color: $secondary-light;
        font-size: 135%;
      }
    }
 

  + ul {
    margin-top: rem-calc($global-margin*0.5);
    li {
      list-style-type: circle;
    }
  }
  &.no-bullet, &.checked {
    @include breakpoint(medium) {
      margin-left: 0;
    }
    list-style-type: none;
    li {
      display: flex;
      margin-left: 0;
      &.radio-button {
        margin-left: -10px;
        @include breakpoint(medium) {
          margin-left: -15px;
        }
      }
    }
  }
  &.checked {
    li {
      .icon {
        @include box(rem-calc(23), rem-calc(20), false);
        fill: $success;
        margin-right: rem-calc(13);
        margin-top: rem-calc(1);
        @include breakpoint(medium) {
          margin-top: rem-calc(2);
        }
        flex: 0 0 auto;
      }
    }
  } 
}

address {
  font-style: normal;
  margin-bottom: $global-margin;
}

.site-container {
  &--error-page {
    ul {
      li {
        @include breakpoint(medium) {
          font-size: $large-font-size;
        }
      }
    }
  }
}

dl {
  margin-top: 0;
  dt {
    font-size: $small-font-size;
  }
  dd {
    word-wrap: break-word;
    margin-left: 0;
  }
}

legend {
  line-height: $global-lineheight;
  margin-bottom: $global-margin*0.5;

  &.with-checkbox {
    margin-bottom: 0;
  }
}

a {
  transition: color 0.3s;
  color: $secondary;
  &:link {
    text-decoration: underline;
  }
  &:visited {
    color: $secondary;
  }
  @include setInteractiveStyle($link: true);
  &.active {
    color: $primary-dark;
  }
}
div, span {
  &.active {
    color: $primary-dark;
  }
}
div {
  &.active {
    border-bottom: 1px solid transparent;
    display: flex;
    &.user-name {
      min-height: 100%;
      align-items: center;
    }
  }
}
div, .button.clear {
    &.user-name {
      font-weight: $global-weight-bold;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: rem-calc(50);    
        right: rem-calc(12);
        top: 0;
        background-image: linear-gradient(90deg, transparent 0%, $pale-gray 90%);
        @include breakpoint(large-mobile) {
          background-image: none;
        }
      }
      text-overflow: clip;
      overflow: hidden;
      vertical-align: bottom;
      white-space: nowrap;
      max-width: rem-calc(215);
      @include breakpoint(large-mobile) {
        max-width: none;
      }
    }
}

.help-popup {
  p {
    a {
      color: white;
      &:link, &:visited {
        color: white;
      }
      @include setInteractiveStyle($link: true, $light: true);
    }
  }
}

blockquote {
  margin:0;
}

.unit-player {
  &-header {
    &__content {
      h1,
      .h1 {
        color: $secondary-dark;
        font-size: rem-calc(unitPlayerHeadingFontSize(small, h1));
        width: 85%; // account for close button
        margin: 0;
        line-height: 1.2;
        @include breakpoint(medium) {
          font-size: rem-calc(unitPlayerHeadingFontSize(medium, h1));
        }
      }
    }

    &--with-progress-bar {
      .unit-player-header__content {
        h1 {
          font-size: rem-calc(
            unitPlayerHeadingFontSize(small-with-progress, h1)
          );

          @include breakpoint(medium) {
            font-size: rem-calc(
              unitPlayerHeadingFontSize(medium-with-progress, h1)
            );
          }
        }
      }
    }
  }
  &-content {
    h2 {
      width: 84%; // account for help
      font-size: rem-calc(unitPlayerHeadingFontSize(small-with-progress, h2));

      @include breakpoint(medium) {
        font-size: rem-calc(
          unitPlayerHeadingFontSize(medium-with-progress, h2)
        );
      }
    }
    ul {
      margin-bottom: rem-calc($global-margin * 1.5);
    }

    &--summary {
      h2 {
        font-size: rem-calc(unitPlayerHeadingFontSize(small, h2));

        @include breakpoint(medium) {
          font-size: rem-calc(unitPlayerHeadingFontSize(medium, h2));
        }

        line-height: $global-lineheight;
      }

      h3 {
        font-size: rem-calc(unitPlayerHeadingFontSize(small, h3));

        @include breakpoint(medium) {
          font-size: rem-calc(unitPlayerHeadingFontSize(medium, h3));
        }

        line-height: $global-lineheight;
      }
    }

    @include breakpoint(medium) {
      p:not(.callout p, .help-popup p),
      ol,
      ul,
      dl dt,
      dl dd,
      legend,
      label {
        font-size: $large-font-size;
      }
    }
    &__subject-complete {
      p {
        font-size: $large-font-size;
        margin-bottom:0;
        @include breakpoint(medium) {
          font-size: $largest-font-size!important;
          font-weight: $global-weight-bold;
          margin-bottom: $global-margin;
        }
      }
      &__next-steps {
        h3 {
          font-size: $global-font-size;
          color: $secondary-dark;
          font-weight: $global-weight-bold;
          margin-bottom: rem-calc($grid-column-gutter-single-small);
          @include breakpoint(medium) {
            font-size: $large-font-size;
            margin-bottom: rem-calc($grid-column-gutter-single-medium);
          }
        }
      }
    }
  }
}

.sign-in {
  h1 {
    color: $secondary-dark;
    font-size: rem-calc(20);
    line-height: $header-lineheight;
    width: 82%;
    line-height: $button-text-lineheight;
    @include breakpoint(medium) {
      font-size: rem-calc(22);
      width: 89%;
    }
  }

  label {
    @include breakpoint(medium) {
      font-size: $large-font-size;
    }
  }
}

.demographics {
  &-choice,
  &-questions {
    legend {
      line-height: $header-lineheight;
    }
  }

  &-questions {
    legend {
      font-size: rem-calc(17);
      font-weight: $global-weight-bold;
      color: $secondary-light;

      @include breakpoint(medium) {
        font-size: rem-calc(19);
      }

      span {
        &:last-child {
          font-size: 88%;
        }
      }
    }
  }
}

.footer {
  ul {
    margin-bottom: 0;
    margin-top: rem-calc($grid-column-gutter-single-small);
    line-height: 1.2;

    li {
      padding-bottom: rem-calc(6);
      align-items: center;
    }
  }
}

.browse {
  p {
    margin-bottom: rem-calc(20);
  }
}

label {
  font-weight: $global-weight-bold;
  color: $secondary-dark;
}

.radio-button,
.checkbox {
  input[type='radio'],
  input[type='checkbox'] {
    + label {
      font-weight: $global-weight-normal;
      line-height: $header-lineheight;
    }
  }
}

.no-email-mobile__popout {
  > p {
    font-weight: $global-weight-bold;
    line-height: $header-lineheight-small;
  }
}

.header__menu-links {
  li {
    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
    @include breakpoint(large) {
      font-size: $largest-font-size;
    }
  }
  .active {
    background-color: white;
    color: $primary-dark;
  }
}

.callout--info {
  p {
    font-weight: $global-weight-bold;
    font-size: $small-font-size;
    color: $callout-font-color;
    line-height: $header-lineheight;

    @include breakpoint(medium) {
      font-size: $global-font-size;
    }
  }
}

.text-medium-left {
  @include breakpoint(medium) {
    text-align: left;
  }
}

.home-container {
  p {
    @include breakpoint(medium) {
      width: 95%;
    }
    @include breakpoint(tablet-portrait) {
      font-size: $large-font-size;
    }
  }

  h2 {
    margin-top: rem-calc($global-margin);
    @include breakpoint(medium) {
      margin-top: rem-calc($grid-column-gutter-single-small);
    }
    @include breakpoint(large) {
      margin-bottom: $global-padding * 1.5;
    }
  }
}

.whitespace {
  &-wrap {
    white-space: normal !important;
  }
}

.reveal {
  &.modal-with-content {
    h1 {
      margin-top: 0;
      font-size: 140%;
    }
    h2 {
      font-size: 110%;
    }
    h3 {
      font-size: 100%;
    }
    @include breakpoint(medium) {
      h1 {
        font-size: 175%;
      }
      h2 {
        font-size: 140%;
      }
      h3 {
        font-size: 110%;
      }
    }
  }
}
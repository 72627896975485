.help-opener {
  display: none;
  z-index: 9999;
  @include position(fixed, 6.125, 0.25, 0, null);
  &--under-progress-bar {
    top: 5.3125rem !important;
  }
  line-height: normal;
  padding: 0;
}
.help-popout {
  display: none;
  z-index: 10000;
  min-width: rem-calc(242);
  @include position(fixed, 5.875);
  right: rem-calc(-300);
  background: none transparent;
  border-top-left-radius: rem-calc(24);
  border-bottom-left-radius: rem-calc(24);
  @include shadow(0 0 12px 0 rgba(0, 0, 0, 0.15));
  &--under-progress-bar {
    top: 5.0625rem !important;
  }
  &__close {
    color: $secondary-dark;
    border: none;
    @include shadow(none);
    margin-right: rem-calc(8);
    -webkit-font-smoothing: auto;
    .button__content__text {
      display: inline-block;
      padding-top: rem-calc(1);
    }
    .icon {
      width: rem-calc(34);
      height: rem-calc(34);
      margin-left: rem-calc(2);
    }
  }
  &__header {
    background: none $pale-gray;
    min-height: rem-calc(48);
    border-bottom: 1px solid $lighter-gray;
    border-top-left-radius: rem-calc(24);
  }
  &__content {
    padding: rem-calc(14) rem-calc(14) 0 rem-calc(14);
    ul {
      list-style-type: none;
      text-align: right;
      margin-bottom: 0;
      li {
        vertical-align: middle;
        padding-bottom: rem-calc(14);
        button.hollow--circle {
          margin-left: rem-calc(10);
          .icon {
            width: 90%;
            height: 90%;
            &--audio-muted {
              width: 95%;
              height: 95%;
              margin-left: rem-calc(2);
            }
          }
        }
      }
    }
  }
  &__content,
  &__footer {
    background: none white;
  }
  &__footer {
    border-top: 1px solid $light-gray;
    border-bottom-left-radius: rem-calc(24);
    min-height: rem-calc(74);
    button {
      height: rem-calc(40);
    }
  }
}

.callout {
  border-width: 1px;
  padding: $global-padding * 0.375;
  @include breakpoint(medium) {
    padding: $global-padding * 0.5;
  }
  width: 100%;

  &:not(.no-animation) {
    animation: slide-down-fade-in 0.4s ease;
  }

  &--info {
    .icon {
      fill: $callout-font-color;
      flex: 0 0 rem-calc(26);
      width: rem-calc(26);
      height: rem-calc(26);
      margin-right: rem-calc(6);
      margin-left: 0;
    }

    p {
      vertical-align: middle;
      margin-bottom: 0;
    }

    &-box {
      border: 1px solid $secondary-lighter;
      border-radius: $global-radius;
      background-color: $pale-blue;
      position: fixed;
      bottom: rem-calc(map-get($player-footer-height, small)+3);
      @include makeFullWidth($gutter: true);
      @include shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
      left: $grid-column-gutter-single-small;
      margin: $grid-column-gutter-single-small 0;
      z-index: 300;

      @include breakpoint(medium) {
        left: $grid-column-gutter-single-medium;
        bottom: rem-calc(map-get($player-footer-height, medium)+2);
        margin: $grid-column-gutter-single-medium 0;
      }
      &.full-page {
        @include position(relative, 0, 0, 0, 0);
        max-width: rem-calc(680);
        width: 100%;
      }
    }

    &-text {
      border: none;
      padding-top: 0;
      padding-left: 0;
      margin: rem-calc(6) 0 0 0;
      background: none transparent;
      @include breakpoint(medium) {
        margin-top: rem-calc(8);
      }
      p {
        padding-top: rem-calc(1);
      }
      .icon {
        margin: 0 rem-calc(6) 0 rem-calc(-2);
        flex: 0 0 rem-calc(24);
        width: rem-calc(24);
        height: rem-calc(24);
      }
    }
  }

  &--register {
    border: none;
    display: flex;
    padding: 0;
    margin: 0;

    p {
      margin-bottom: 0;
      line-height: 1;
    }

    hr {
      width: 100%;
    }

    &-vertical {
      flex-direction: column;
      @include flex-align(center, middle);
      margin: $global-margin 0 $global-margin*0.5 0;
      p {
        margin-bottom: $global-margin*0.5;
      }
    }
  }
}

form {
  .callout--info-text {
    margin-left: rem-calc(4);
    max-width: $input-group-max-width;
  }
}

.sign-in {
  &--username {
    .callout--info-box {
      @include position(relative, 0, 0, 0, 0);
    }
  }

  &-details__panel {
    .callout--info-box {
      border-left: none;
      border-right: none;
      border-radius: 0;
      border-color: $light-gray;
      width: calc(100% - 2px); // So it sits inside
      margin: 0 auto;
      padding-left: rem-calc(4);
      @include shadow(none);
      @include position(relative, 0, 0, 0, 0);
      .icon {
        @include box(rem-calc(24), rem-calc(24), false);
        margin-right: rem-calc(4);
      }
      @include breakpoint(medium) {
        padding: rem-calc(8) rem-calc(8) rem-calc(8) rem-calc(6);
        .icon {
          margin-top: rem-calc(-2);
          margin-left: rem-calc(-2);
          @include box(rem-calc(26), rem-calc(26), false);
          flex: 0 0 rem-calc(28);
        }
      }
    }
  }
}

.forgotten-password {
  &-popout {
    .callout--info {
      &-text {
        margin-left: 0;
        margin-bottom: $global-margin * 0.5;
        margin-top: rem-calc(6);
      }
      p {
        font-size: $small-font-size;
        padding-top: rem-calc(1.5);
      }
    }
  }
}
.feedback-callout {
  .callout--info-box {
    border: none;
    font-weight: $global-weight-bold;
    background: none $white;
    justify-content: center;
    span {
      font-size: $global-font-size;
      @include breakpoint(small-mobile) {
        font-size: $large-font-size;
      }
      @include breakpoint(medium) {
        font-size: rem-calc(22);
        line-height: 1.2;
      }
    }
    .icon {
      flex-basis: auto;
      margin: 0 0 0  $global-margin * 0.5;
      @include box(rem-calc(22), rem-calc(22), false);
      @include breakpoint(small-mobile) {
        @include box(rem-calc(32), rem-calc(32), false);
      }
      &--checkmark {
        fill: $success;
      }
      &--warning {
        fill: $warning;
        @include breakpoint(small-mobile) {
          margin-top: rem-calc(-2px);
          @include box(rem-calc(28), rem-calc(28), false);
        }
        @include breakpoint(medium) {
          margin-top: rem-calc(2px);
        }
      }
    }
  }
  &--message {
    opacity: 0;
    .callout--info-box {
      padding: rem-calc(2px);
      @include breakpoint(small-mobile) {
        padding: rem-calc($grid-column-gutter-single-small);
      }
      @include positionElementHorizontalCenter;
      @include shadow(none);
      padding: rem-calc($grid-column-gutter-single-small);
      margin: 0;
      width: 100%;
      bottom: rem-calc(map-get($sign-in-footer-height, small));
      animation: slide-down-fade-in-success 0.4s ease forwards;
      @include breakpoint(medium) {
        bottom: rem-calc(map-get($sign-in-footer-height, medium));
      }
      span {
        font-size: $large-font-size;
        text-align: center;
        line-height: 1;
        @include breakpoint(medium) {
          font-size: rem-calc(22);
        }
      }
    }
    &-slider {
      min-width: rem-calc(256);
      transition-property: bottom, transform;
      transition-duration: .4s;
      transition-timing-function: ease-in-out;
      padding-bottom: 0;
      z-index: 100;
      &--password {
        z-index: 105;
      }
      width: 100%;
      @include positionElementHorizontalCenter;
      transform: translate(-50%, 108%);
      bottom: 0;
      max-width: calc(100% - #{$grid-column-gutter-single-medium * 4});
      &--verification-confirm {
        z-index: 105;
        @include breakpoint(tablet-portrait) {
          max-width: rem-calc(450px);
        }
      }
      &.opened {
        transform: translate(-50%, 0);
      }
      &--full-page-form-footer {
        transform: translate(-50%, 110%);
        &.opened {
          transform: translate(-50%, 0);
        }
        &--large {
          transform: translate(0, 110%);
          left: rem-calc($global-padding * 0.5);
          &.opened {
            transform: translate(0, 0);
          }
        }
      }
      .callout--info-box {
        border-radius: $global-radius $global-radius 0 0;
        padding: rem-calc($global-padding * 0.75);
        margin: 0;
        width: 100%;
        position: relative;
        left: 0;
        transform: none;
        bottom: 0;
        @include shadow(0 0 10px rgba(0,0,0,0.2));
      }
    }
    &--verification-code {
      .callout--info-box {
        bottom: 0;
        background: none transparent;
      }
      &-registration {
          justify-content: flex-start;
          @include breakpoint(large) {
            justify-content: center;
          }
          .callout--info-box {
            position: relative;
            left: 0;
            transform: none;
            padding-left: rem-calc(6);
            @include breakpoint(large) {
              @include positionElementHorizontalCenter;
            }
          }
      }
    }
  }
}

.callout--info-box {
  &.top {
    @include position(relative, 0, 0, 0, 0);
  }
}
.banner-image {
  margin: $grid-column-gutter-single-small $grid-column-gutter-single-small*-1 $global-margin*.75 $grid-column-gutter-single-small*-1;
  @include responsiveBackgroundImage();
  min-height: rem-calc(144);
  background-position-y: 30%;

  &--medium {
    margin: 0;
    position: absolute;
    right: 0;
    height: calc(100% - #{$global-margin*3});
    min-height: calc(100% - #{$global-margin*3});
    width: 100%;
    margin-top: $global-margin*1.5;
    border-radius: $large-image-radius;
  }

  &--tertiary {
    @include makeFullWidth($medium-breakpoint: false);
    @include addGutterWidthNegativeMargins($medium-breakpoint: false);
    min-height: rem-calc(228);
    margin-bottom: rem-calc($global-margin);

    @include breakpoint(medium) {
      border-radius: $large-image-radius;
      min-height: rem-calc(290);
      margin: 0;
    }
  }

  &--pixel-depth_1x {
    display: block;
  }

  &--pixel-depth_2x {
    display: none;
  }

  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
      &--pixel-depth_2x {
        display: block;
      }
      &--pixel-depth_1x {
        display: none;
      }
  }
}
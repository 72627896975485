.checkbox-group {
  .checkbox {
    border-top: 1px solid $light-gray;

    &:last-child {
      border-bottom: 1px solid $light-gray;
    }
  }
}

.radio-button,
.checkbox {
  @include addGutterWidthNegativeMargins($small-breakpoint: true, $medium-breakpoint: true, $large-breakpoint: true);

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    @include setInteractiveStyle($radio-checkbox: true);

    +label {
      min-height: rem-calc(45);
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 $grid-column-gutter-single-small;

      @include breakpoint(medium) {
        min-height: rem-calc(55);
        padding: 0 $grid-column-gutter-single-medium;
      }

      position: relative;

      &:before {
        content: '';
        display: inline-block;
        position: relative;
        @include box(rem-calc(25), rem-calc(25), true);

        flex: 0 0 rem-calc(25);

        @include breakpoint(medium) {
          @include box(rem-calc(30), rem-calc(30), true);
          flex: 0 0 rem-calc(30);
        }

        border: 1px solid $primary-dark;
        margin-right: rem-calc(8);
        @include shadow(inset 2px 2px 3px rgba(0, 0, 0, 0.25));
      }
    }
  }

  input[type='checkbox'] {
    +label {
      &:before {
        @include box(rem-calc(25), rem-calc(25), false);

        @include breakpoint(medium) {
          @include box(rem-calc(30), rem-calc(30), false);
          flex: 0 0 rem-calc(30);
        }

        border-radius: 4px !important;
      }
    }
  }

  input[type="text"] {

    margin: 0 $grid-column-gutter-single-small $grid-column-gutter-single-small $grid-column-gutter-single-small;
    width: calc(100% - #{$grid-column-gutter-single-small * 2});

    @include breakpoint(medium) {
      margin: 0 $grid-column-gutter-single-medium $grid-column-gutter-single-medium $grid-column-gutter-single-medium;
      width: calc(100% - #{$grid-column-gutter-single-medium * 2});
    }

    min-height: rem-calc(50);
  }

  &__dot {
    display: none;
    left: rem-calc(15);
    position: absolute;
    align-self: center;
    @include box(rem-calc(15), rem-calc(15), true);

    @include breakpoint(medium) {
      left: rem-calc(20);
      @include box(rem-calc(20), rem-calc(20), true);
    }

    border: none;
    background: none $primary-dark;
  }

  &__check {
    position: relative;

    .icon {
      @include box(rem-calc(20), rem-calc(20), false);
      position: absolute;
      fill: $primary-dark;
      left: rem-calc(-30);
      top: rem-calc(-10);
      display: none;

      @include breakpoint(medium) {
        left: rem-calc(-34);
        top: rem-calc(-12);
        @include box(rem-calc(24), rem-calc(24), false);
      }
    }
  }

  input[type='radio']:checked {
    +label {
      .radio-button__dot {
        display: flex;
      }
    }
  }

  input[type='checkbox']:checked {
    +label {
      .checkbox__check {
        .icon {
          display: inline-block;
        }
      }
    }
  }
}

#ccc {
  .checkbox-toggle {
    top: rem-calc(-2);
    &:focus-within {
      outline: 1px !important;
    }

    &.checkbox-toggle--dark {
      background-color: transparent !important;
      border: 1px solid white !important;
    }

    &--checkbox {

      .checkbox-toggle-label {
        margin-bottom: 0;
        padding: 0;
        width: rem-calc(30)!important;
        height: rem-calc(30)!important;

        &:focus-within {
          outline: 1px !important;
        }

        .checkbox-toggle-toggle {
          background-color: transparent !important;

          &:focus-within {
            outline: 1px !important;
          }
        }
      }

      input:checked {
        &~.checkbox-toggle-toggle {
          &:after {
            border: none !important;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23ffffff' d='M20.25 3l-11.25 11.25-5.25-5.25-3.75 3.75 9 9 15-15z'></path></svg>");
            background-repeat: no-repeat;
            height: 100% !important;
            left: 0 !important;
            top: 0 !important;
            transform: none !important;
            width: 100% !important;
            background-position: center;
          }
        }
      }
    }


  }

}
.header {
  &__menu-links {
    &--mobile {
      width: calc(100% - 20px);
      @include breakpoint(medium) {
        width: calc(100% - 30px);
      }
      position: absolute;
      top: 58px;
      display: block;
      background: none white;
      z-index: 3;
      left: 50%;
      transform: translateX(-50%);
      padding: rem-calc(12) rem-calc(12) rem-calc(2) rem-calc(12);
      border-radius: $global-radius;
      li {
        padding-bottom: 0;
        a {
          text-decoration: none;
        }
        a, span {
          display: inline-block;
          width: 100%;
          padding: $grid-column-gutter-single-small;
          background-color: $pale-gray;
          border-radius: $global-radius;
          font-size: 1.125rem;
          font-weight: 500;
          margin-bottom: $grid-column-gutter-single-small;
          &:not(.active, &:active) {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 24 24'><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='%23e6007e'></path></svg>");
            background-repeat: no-repeat;
            background-position: right center;
            &:hover {
              background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 24 24'><path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='%23a71663'></path></svg>");
            }
          }
        }
      }
    }
    @include breakpoint(tablet-portrait) {
      flex-grow: 0;
      width: 60%;
    }

    &.no-bullet {
      @include breakpoint(large) {
        margin: $global-margin * 2.5 0;
      }
    }

    li {
      @include breakpoint(tablet-portrait) {

        padding-bottom: 0;
        flex-grow: 1;
        justify-content: center;
      }
    }
    + nav {
      ul {
        li {
          &.first-item {
            @include breakpoint(tablet-portrait) {
              border-left: 1px solid $light-gray;
            }
            @include breakpoint(large) {
              padding-left: rem-calc($grid-column-gutter-single-small);
            }
          }
        }
      }
    }
  }
}

nav {
  &.back-to-navigation {
    border-bottom: 1px solid $secondary-lightest;
    background: none white;

    &__bottom {
      border-top: 1px solid $secondary-lightest;
      border-bottom: none;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 101;
    }

    .cell {
      min-height: rem-calc(45);
    }

    @include breakpoint(large) {
      display: none;
    }
  }

  &.breadcrumb {
    border-bottom: 1px solid $secondary-lightest;
    background: none $pale-gray;

    .cell {
      min-height: rem-calc(48);
    }
  }
}
.browse {
  &__list-container {
    padding: 0;
    margin-bottom: $global-margin * 2;

    @include breakpoint(medium) {
      padding: 0 $grid-column-gutter-single-medium;
    }
  }

  ul {
    li {
      padding-bottom: $global-padding*0.5;
      display: block;

      @include breakpoint(medium) {
        padding-bottom: $global-padding;

        &:nth-child(odd) {
          padding-right: $grid-column-gutter-single-medium;
        }
      }

      @include breakpoint(large) {
        padding-bottom: $global-padding * 1.5;
      }
    }
  }
}
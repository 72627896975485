form,
.form {
  z-index: 2;
  position: relative;

  &--centre-selector {
    z-index: 0;
  }

  &--support-type, &--terms-privacy {
    padding-bottom: rem-calc(map-get($player-footer-height, medium) * 2)
  }

  &--input-group {
    @include makeFullWidth(
      $small-breakpoint: false,
      $medium-breakpoint: false,
      $large-breakpoint: true
    );
    @include addGutterWidthNegativeMargins(
      $small-breakpoint: false,
      $medium-breakpoint: false,
      $large-breakpoint: true
    );
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    @include breakpoint(large) {
      background: none $lightest-gray;
      border-top: 1px solid $secondary-lightest;
      border-bottom: 1px solid $secondary-lightest;
      padding: $global-padding * 3 0 $global-padding * 4 0;
      &__inner {
        margin: 0 auto;
        max-width: $input-group-max-width;
        &--small {
          max-width: rem-calc($input-group-code-max-width);
          &-postcode {
            max-width: rem-calc($input-group-postcode-max-width);
          }
        }
      }
    }
    &--password {
      z-index: 105;
      @include breakpoint(large) {
        padding-top: 0;
      }
      &--existing {
        @include breakpoint(large) {
          padding-top: $global-padding * 3;
        }
      }
    }
    &--small {
      @include makeFullWidth(
        $small-breakpoint: true,
        $medium-breakpoint: true,
        $large-breakpoint: true
      );
      @include addGutterWidthNegativeMargins(
        $small-breakpoint: true,
        $medium-breakpoint: true,
        $large-breakpoint: true,
      );
      background: none $lightest-gray;
      border-top: 1px solid $secondary-lightest;
      border-bottom: 1px solid $secondary-lightest;
      padding: $global-padding * 3 0 $global-padding * 4 0;
    }
    &__inner {
      &--small {
        max-width: rem-calc($input-group-code-max-width);
        @include breakpoint(large) {
           margin: 0 auto;
        }
        &-postcode {
          max-width: rem-calc($input-group-postcode-max-width);
        }
      }
    }
  }
}

label {
  display: block;
  line-height: 1;
  margin-bottom: $global-padding * 0.5;
  padding-left: $global-padding * 0.375;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;

  legend {
    &.show-for-sr {
      white-space: normal!important;
    }
  }
}

.sign-in {
  form {
    z-index: 101;
    fieldset {
      padding: 0 rem-calc($grid-column-gutter-single-small);
      @include breakpoint(medium) {
        padding: 0 rem-calc($grid-column-gutter-single-medium);
      }
    }
  }
  &--password {
    form {
      fieldset {
        padding-top: 1.75rem;
      }
    }

    .forgotten-password-popout--code {
      form {
        label {
          @include breakpoint(medium) {
            font-size: rem-calc(17);
          }
        }
      }
    }
  }
}

input {
  &[type='text'],
  &[type='number'],
  &[type='email'],
  &[type='password'] {
    min-height: rem-calc(44);
    font-size: $global-font-size;
    @include breakpoint(medium) {
      min-height: rem-calc(52);
    }
  }
  &[type='password'] {
    &::-ms-reveal {
      display: none;
    }
  }
  &[type='button'],
  &[type='submit'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &:-internal-autofill-selected {
    background-color: white!important;
    font-size: $global-font-size;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

textarea,
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  box-sizing: border-box;
  padding: 0 $global-padding * 0.875;
  width: 100%;
  font-family: inherit;
  font-weight: normal;
  line-height: 1;
  border: 1px solid $secondary-dark;
  border-radius: $global-radius;
  background-color: white;
  @include shadow(inset 2px 1px 4px 0 $dark-gray);
  color: $secondary-dark;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include setInteractiveStyle($input: true);
  @include breakpoint(medium) {
    font-size: $large-font-size;
  }
  &.transparent-disabled {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
}

input[type='number'] {
  appearance: textfield;
  &:hover, &:focus {
    -webkit-appearance: textfield;
    appearance: textfield;
  }
  &.centre-id {
    max-width: rem-calc(200);
    letter-spacing: rem-calc(1.7);
  }
}

input[type='text'] {
  &#description {
    max-width: rem-calc($input-group-max-width);
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: inset 2px 1px 4px 0 #bfbfbf!important;
  box-shadow: inset 2px 1px 4px 0 #bfbfbf!important;
  background-color: white!important;
}

input:-internal-autofill-selected {
  -webkit-box-shadow: inset 2px 1px 4px 0 #bfbfbf!important;
  box-shadow: inset 2px 1px 4px 0 #bfbfbf!important;
  background-color: white!important;
  color: $secondary-dark!important
}

input:-webkit-autofill::first-line {
  font-size: $global-font-size!important;
  color: $secondary-dark!important
}

.form-disabled-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  background: none white;
  @include animate(
    fade-up-to-80,
    opacity,
    0,
    0.8,
    0.7s,
    cubic-bezier(0.84, 0, 0.17, 1),
    forwards,
    6
  );
}

input:-internal-autofill-selected {
  color: $secondary-dark!important;
}
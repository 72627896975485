.icon {
  display: inline-block;
  fill: $secondary-dark;
  vertical-align: middle;
  transition: fill 0.3s;
  @include box(rem-calc(24), rem-calc(24), false);
  flex-shrink: 0;

  &--material {
    margin-top: rem-calc(-2);
  }

  &--primary {
    fill: $primary;
    &-dark {
      fill: $primary-dark;
    }
  }

  &--light {
    fill: white;
  }

  &--secondary {
    fill: $secondary-dark;
  }

  &--success {
    fill: $success;
  }

  &--open-in-new {
    vertical-align: text-bottom;
  }

  &--subject-complete-rosette {
    filter: drop-shadow(2px 5px 4px rgb(0 0 0 / 0.1));
    @include box(rem-calc(66), rem-calc(67), false);
    @include breakpoint(medium) {
      @include box(rem-calc(113), rem-calc(115), false);
    }
    g {
      ellipse {
        fill: white;
      }
      path {
        &#background {
          fill: white
        }
        &#foreground {
          fill: $reward-yellow
        }
      }
      use {
        fill: $reward-yellow
      }
    }
  }
  &--browse-list {
    @include box(rem-calc(32), rem-calc(32), false);
    margin-right: rem-calc(-3px);
    @include breakpoint(medium) {
      @include box(rem-calc(36), rem-calc(36), false);
      margin-right: rem-calc(-5px);
    }
  }

  &--progress {
    @include box(rem-calc(35), rem-calc(36), false);
    fill: none;
    path {
      fill: $secondary-dark;
    }
    g {
      rect {
        stroke: $secondary-dark;
        stroke-width: 1.5;
        fill: white;
      }
    }
  }

  &--help {
    @include box(rem-calc(34), rem-calc(34), false);
    @include breakpoint(large) {
      @include box(rem-calc(44), rem-calc(44), false);
    }
  }
  &--help-circle {
    border-radius: 50%;
    border: 2px solid $secondary-dark;
    padding: rem-calc(1);
    @include box(rem-calc(24), rem-calc(24));
    min-width: rem-calc(24);
  }
  &--search,
  &--hamburger {
    @include box(rem-calc(36), rem-calc(36), false);
    @include breakpoint(large) {
      @include box(rem-calc(48), rem-calc(48), false);
    }
  }
  &--print {
    @include breakpoint(medium) {
      @include box(rem-calc(36), rem-calc(36), false);
    }
  }
  &--welsh-dragon {
    @include box(rem-calc(42), rem-calc(26), false);
  }
  &--swap {
    @include box(rem-calc(30), rem-calc(30), false);
    margin-right: -5px;
  }
}

.icon-on-left {
  .icon {
    margin-right: rem-calc(5);
    margin-top: 0;
    &--user {
      margin-top: rem-calc(-4);
    }
    fill: $secondary-dark;
    &--primary-dark {
      fill: $primary-dark;
    }

    &--mobile {
      margin-top: rem-calc(-2);
      margin-left: rem-calc(-4);
    }

    &--success {
      fill: $success;
    }
  }
}

.site-container--edit-my-details {
  .icon {
    &--user {
      fill: $secondary-light;
      @include box(rem-calc(32), rem-calc(32), false);
      margin-right: rem-calc(6);
      margin-top: rem-calc(-4);
      @include breakpoint(medium) {
        @include box(rem-calc(36), rem-calc(36), false);
      }
    }
  }
}
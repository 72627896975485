.loader-background {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 100%;
  height: calc(
    100% -
      (
        #{map-get($player-footer-height, small) +
          map-get($player-header-height-with-progress, small) +
          map-get($progress-bar-height, small) + $progress-bar-shadow-height}
      )
  );
  top: map-get($player-header-height-with-progress, small) +
    map-get($progress-bar-height, small) + $progress-bar-shadow-height;
  @include breakpoint(medium) {
    height: calc(
      100% -
        (
          #{map-get($player-footer-height, medium) +
            map-get($player-header-height-with-progress, medium) +
            map-get($progress-bar-height, medium)  + $progress-bar-shadow-height}
        )
    );
    top: map-get($player-header-height-with-progress, medium) +
      map-get($progress-bar-height, medium) + $progress-bar-shadow-height;
  }
  left: 0;
  z-index: 5000;
}

.loader {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  &__graphic {
    svg {
      @include box(rem-calc(40), rem-calc(40));
      @include breakpoint(medium) {
        @include box(rem-calc(60), rem-calc(60));

      }
      rect {
        fill: $primary;
      }
    }
    margin: 0 auto;
    &--centre-lookup {
      svg {
        @include box(rem-calc(24), rem-calc(24));
        @include breakpoint(medium) {
          @include box(rem-calc(24), rem-calc(24));
        }
      }
    }
  }
  &__text {
    font-size: $large-font-size;
    color: $secondary-dark;
    margin-top: $global-margin*0.5;
  }
}

.browse__list-container {
  .loader-background {
    position: relative;
    top: 0;
    height: rem-calc(100);
  }
}
